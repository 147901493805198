/* eslint-disable hubspot-dev/no-private-classes */
export const navigation = {
  '/getting-started': {
    items: [{
      position: 1,
      url: '/overview',
      label: 'Getting started overview'
    }, {
      position: 1,
      url: '/what-to-build',
      label: 'What to build'
    }, {
      position: 3,
      url: '/tools-to-help-you-build',
      label: 'Tools to help you build'
    }, {
      url: '/account-types',
      label: 'HubSpot Account Types'
    }, {
      label: 'slack',
      items: [{
        url: '/slack/developer-slack',
        label: 'Join the HubSpot Developer Slack Community'
      }, {
        url: '/slack/code-of-conduct',
        label: 'The HubSpot Developer Slack Community Code of Conduct'
      }],
      dir: 'slack'
    }]
  },
  '/reference/cms': {
    items: [{
      position: 1,
      url: '/overview',
      label: 'CMS Reference Overview'
    }, {
      label: 'modules',
      items: [{
        url: '/modules/files',
        label: 'Coding Custom Modules'
      }, {
        url: '/modules/configuration',
        label: 'Configuring a module'
      }, {
        url: '/modules/default-email-modules',
        label: 'Default email modules'
      }, {
        url: '/modules/default-module-versioning',
        label: 'Default module versioning'
      }, {
        url: '/modules/default-modules',
        label: 'Default web modules'
      }, {
        url: '/modules/deprecated',
        label: 'Deprecated default modules'
      }, {
        url: '/modules/export-to-template-context',
        label: 'export_to_template_context'
      }, {
        url: '/modules/using-modules-in-templates',
        label: 'Using modules in templates'
      }],
      dir: 'modules',
      position: 1
    }, {
      label: 'hubl',
      items: [{
        position: 1,
        url: '/hubl/overview',
        label: 'HubL Syntax'
      }, {
        position: 2,
        url: '/hubl/operators-and-expression-tests',
        label: 'Operators & Expression Tests'
      }, {
        position: 3,
        url: '/hubl/filters',
        label: 'HubL filters'
      }, {
        position: 4,
        url: '/hubl/functions',
        label: 'HubL functions'
      }, {
        position: 5,
        url: '/hubl/loops',
        label: 'For Loops'
      }, {
        label: 'tags',
        items: [{
          position: 1,
          url: '/hubl/tags/standard-tags',
          label: 'HubL standard tags'
        }, {
          position: 2,
          url: '/hubl/tags/dnd-areas',
          label: 'Drag and Drop Areas HubL Tags'
        }, {
          position: 3,
          url: '/hubl/tags/deprecated',
          label: 'Deprecated HubL Supported Tags'
        }, {
          position: 4,
          url: '/hubl/tags/related-blog-posts',
          label: 'Creating a related blog post listing with the blog related posts HubL tag'
        }],
        dir: 'tags',
        position: 6
      }, {
        position: 7,
        url: '/hubl/variables',
        label: 'HubL variables'
      }, {
        position: 8,
        url: '/hubl/variables-macros-syntax',
        label: 'HubL variables & macros syntax'
      }, {
        position: 8,
        url: '/hubl/quote-variables',
        label: 'Quote template variables',
        badge: 'BETA'
      }, {
        position: 9,
        url: '/hubl/if-statements',
        label: 'If statements'
      }, {
        url: '/hubl/deprecated',
        label: 'Deprecated HubL Supported Functions'
      }],
      dir: 'hubl',
      position: 2
    }, {
      position: 3,
      url: '/forms',
      label: 'HubSpot forms'
    }, {
      label: 'fields',
      items: [{
        position: 1,
        url: '/fields/module-theme-fields',
        label: 'Module and theme fields'
      }, {
        url: '/fields/rich-text-editor',
        label: 'Configuring the Rich Text Editor'
      }],
      dir: 'fields',
      position: 4
    }, {
      url: '/serverless-functions',
      label: 'Serverless Reference'
    }]
  },
  '/reference/api': {
    items: [{
      label: 'analytics-and-events',
      items: [{
        label: 'cookie-banner',
        items: [{
          url: '/analytics-and-events/cookie-banner/cookie-banner-api',
          label: 'Cookie banner'
        }, {
          url: '/analytics-and-events/cookie-banner/google-consent-mode',
          label: 'Implement Google Consent Mode'
        }],
        dir: 'cookie-banner'
      }, {
        url: '/analytics-and-events/tracking-code',
        label: 'Tracking code API overview'
      }, {
        label: 'tracking-code',
        items: [{
          url: '/analytics-and-events/tracking-code/v1',
          label: 'Tracking Code API Overview'
        }],
        dir: 'tracking-code'
      }],
      dir: 'analytics-and-events'
    }, {
      label: 'automation',
      items: [{
        url: '/automation/custom-code-actions',
        label: 'Workflows | Custom Code Actions'
      }],
      dir: 'automation'
    }, {
      label: 'conversations',
      items: [{
        url: '/conversations/chat-widget-sdk',
        label: 'Conversations | Chat Widget SDK'
      }],
      dir: 'conversations'
    }, {
      label: 'crm',
      items: [{
        url: '/crm/sensitive-data',
        label: 'Access and manage Sensitive Data'
      }],
      dir: 'crm'
    }, {
      label: 'deprecated',
      items: [{
        url: '/deprecated/overview',
        label: 'HubSpot APIs | Deprecated APIs'
      }, {
        label: 'migrations',
        items: [{
          url: '/deprecated/migrations/migrate-an-existing-account-extension-integration',
          label: 'Migrate an existing Account Extension integration'
        }, {
          url: '/deprecated/migrations/migrate-an-existing-ecommerce-bridge-api-integration',
          label: 'Migrate an existing ecommerce bridge API integration'
        }],
        dir: 'migrations'
      }],
      dir: 'deprecated'
    }, {
      url: '/overview',
      label: 'HubSpot APIs | Getting started'
    }, {
      label: 'marketing',
      items: [{
        url: '/marketing/calls-to-action-sdk',
        label: 'Calls to Action widget SDK'
      }],
      dir: 'marketing'
    }, {
      label: 'other-resources',
      items: [{
        url: '/other-resources/error-handling',
        label: 'Error handling'
      }],
      dir: 'other-resources'
    }]
  },
  '/reference/ui-components': {
    items: [{
      position: 1,
      url: '/overview',
      label: 'UI extension components'
    }, {
      position: 2,
      url: '/figma-design-kit',
      label: 'Figma Design Kit | UI extensions'
    }, {
      label: 'standard-components',
      items: [{
        url: '/standard-components/accordion',
        label: 'Accordion'
      }, {
        url: '/standard-components/alert',
        label: 'Alert'
      }, {
        url: '/standard-components/box',
        label: 'Box'
      }, {
        url: '/standard-components/button',
        label: 'Button'
      }, {
        url: '/standard-components/button-row',
        label: 'ButtonRow'
      }, {
        label: 'charts',
        items: [{
          position: 1,
          url: '/standard-components/charts/overview',
          label: 'Charts overview | UI components (BETA)'
        }, {
          url: '/standard-components/charts/bar-chart',
          label: 'BarChart | UI components (BETA)'
        }, {
          url: '/standard-components/charts/line-chart',
          label: 'LineChart | UI components (BETA)'
        }],
        dir: 'charts'
      }, {
        url: '/standard-components/checkbox',
        label: 'Checkbox'
      }, {
        url: '/standard-components/date-input',
        label: 'DateInput | UI components'
      }, {
        url: '/standard-components/description-list',
        label: 'DescriptionList | UI components'
      }, {
        url: '/standard-components/divider',
        label: 'Divider'
      }, {
        url: '/standard-components/dropdown',
        label: 'Dropdown | UI components'
      }, {
        url: '/standard-components/empty-state',
        label: 'EmptyState | UI components'
      }, {
        url: '/standard-components/error-state',
        label: 'Error state | UI components'
      }, {
        url: '/standard-components/flex',
        label: 'Flex'
      }, {
        url: '/standard-components/form',
        label: 'Form'
      }, {
        url: '/standard-components/heading',
        label: 'Heading'
      }, {
        url: '/standard-components/icon',
        label: 'Icon'
      }, {
        url: '/standard-components/image',
        label: 'Image'
      }, {
        url: '/standard-components/input',
        label: 'Input'
      }, {
        url: '/standard-components/link',
        label: 'Link'
      }, {
        url: '/standard-components/list',
        label: 'List'
      }, {
        url: '/standard-components/loading-button',
        label: 'LoadingButton | UI components'
      }, {
        url: '/standard-components/loading-spinner',
        label: 'LoadingSpinner'
      }, {
        url: '/standard-components/modal',
        label: 'Modal'
      }, {
        url: '/standard-components/multi-select',
        label: 'MultiSelect'
      }, {
        url: '/standard-components/number-input',
        label: 'NumberInput'
      }, {
        url: '/standard-components/panel',
        label: 'Panel'
      }, {
        url: '/standard-components/progress-bar',
        label: 'ProgressBar'
      }, {
        url: '/standard-components/radio-button',
        label: 'RadioButton'
      }, {
        url: '/standard-components/select',
        label: 'Select'
      }, {
        url: '/standard-components/statistics',
        label: 'Statistics'
      }, {
        url: '/standard-components/status-tag',
        label: 'StatusTag | UI components'
      }, {
        url: '/standard-components/step-indicator',
        label: 'StepIndicator'
      }, {
        url: '/standard-components/stepper-input',
        label: 'StepperInput'
      }, {
        url: '/standard-components/table',
        label: 'Table | UI components'
      }, {
        url: '/standard-components/tag',
        label: 'Tag'
      }, {
        url: '/standard-components/text',
        label: 'Text'
      }, {
        url: '/standard-components/text-area',
        label: 'TextArea'
      }, {
        url: '/standard-components/tile',
        label: 'Tile'
      }, {
        url: '/standard-components/toggle',
        label: 'Toggle'
      }, {
        url: '/standard-components/toggle-group',
        label: 'ToggleGroup'
      }],
      dir: 'standard-components',
      position: 3
    }, {
      label: 'crm-action-components',
      items: [{
        url: '/crm-action-components/overview',
        label: 'CRM action components'
      }, {
        url: '/crm-action-components/crm-action-button',
        label: 'CrmActionButton'
      }, {
        url: '/crm-action-components/crm-action-link',
        label: 'CrmActionLink'
      }, {
        url: '/crm-action-components/crm-card-actions',
        label: 'CrmCardActions'
      }],
      dir: 'crm-action-components'
    }, {
      label: 'crm-data-components',
      items: [{
        url: '/crm-data-components/overview',
        label: 'CRM data components (BETA)'
      }, {
        url: '/crm-data-components/crm-association-pivot',
        label: 'CrmAssociationPivot'
      }, {
        url: '/crm-data-components/crm-association-property-list',
        label: 'CrmAssociationPropertyList'
      }, {
        url: '/crm-data-components/crm-association-stage-tracker',
        label: 'CrmAssociationStageTracker'
      }, {
        url: '/crm-data-components/crm-association-table',
        label: 'CrmAssociationTable'
      }, {
        url: '/crm-data-components/crm-data-highlight',
        label: 'CrmDataHighlight'
      }, {
        url: '/crm-data-components/crm-property-list',
        label: 'CrmPropertyList'
      }, {
        url: '/crm-data-components/crm-report',
        label: 'CrmReport'
      }, {
        url: '/crm-data-components/crm-stage-tracker',
        label: 'CrmStageTracker'
      }, {
        url: '/crm-data-components/crm-statistics',
        label: 'CrmStatistics'
      }],
      dir: 'crm-data-components'
    }, {
      url: '/manage-ui-extension-layout',
      label: 'Manage UI extension layout (BETA)'
    }]
  },
  '/guides/api': {
    items: [{
      position: 1,
      url: '/overview',
      label: "How to use HubSpot's APIs"
    }, {
      label: 'analytics-and-events',
      items: [{
        label: 'custom-events',
        items: [{
          position: 1,
          url: '/analytics-and-events/custom-events/custom-event-completions',
          label: 'Send custom event completions'
        }, {
          url: '/analytics-and-events/custom-events/custom-event-definitions',
          label: 'Define custom events',
          badge: 'BETA'
        }],
        dir: 'custom-events',
        position: 1
      }, {
        position: 2,
        url: '/analytics-and-events/email-analytics',
        label: 'Email Events API Overview | Email Events API'
      }, {
        position: 3,
        url: '/analytics-and-events/event-analytics',
        label: 'Events API | Event Analytics'
      }, {
        url: '/analytics-and-events/reporting',
        label: 'Analytics API Overview'
      }],
      dir: 'analytics-and-events',
      position: 2
    }, {
      label: 'app-management',
      items: [{
        url: '/app-management/oauth-tokens',
        label: 'OAuth | Managing tokens'
      }, {
        url: '/app-management/webhooks',
        label: 'Webhooks API '
      }],
      dir: 'app-management',
      position: 3
    }, {
      label: 'automation',
      items: [{
        position: 1,
        url: '/automation/create-manage-workflows',
        label: 'Workflows API Overview | Workflows API'
      }, {
        position: 2,
        url: '/automation/custom-workflow-actions',
        label: 'Automation API | Custom Workflow Actions'
      }, {
        position: 3,
        url: '/automation/sequences',
        label: 'Automation API | Sequences API (BETA)',
        badge: 'BETA'
      }, {
        url: '/automation/workflows-v4',
        label: 'Automation API | Automation v4 API (BETA)',
        badge: 'BETA'
      }],
      dir: 'automation',
      position: 4
    }, {
      label: 'cms',
      items: [{
        label: 'blogs',
        items: [{
          url: '/cms/blogs/blog-authors',
          label: 'CMS API | Blog Authors'
        }, {
          url: '/cms/blogs/blog-posts',
          label: 'CMS API | Blog Posts'
        }, {
          url: '/cms/blogs/blog-tags',
          label: 'CMS API | Blog Tags'
        }],
        dir: 'blogs',
        position: 1
      }, {
        position: 2,
        url: '/cms/content-audit',
        label: 'CMS Content Audit API'
      }, {
        position: 3,
        url: '/cms/domains',
        label: 'CMS API | Domain management'
      }, {
        position: 4,
        url: '/cms/hubdb',
        label: 'CMS API | HubDB'
      }, {
        position: 5,
        url: '/cms/media-bridge',
        label: 'CMS API | Media Bridge'
      }, {
        position: 6,
        url: '/cms/pages',
        label: 'CMS API | Pages'
      }, {
        position: 7,
        url: '/cms/performance',
        label: 'CMS Performance API'
      }, {
        position: 8,
        url: '/cms/site-search',
        label: 'CMS API | Site search'
      }, {
        url: '/cms/source-code',
        label: 'CMS | Source Code API'
      }, {
        url: '/cms/url-redirects',
        label: 'CMS API | URL redirects'
      }],
      dir: 'cms',
      position: 5
    }, {
      label: 'conversations',
      items: [{
        position: 1,
        url: '/conversations/create-custom-channels',
        label: 'Custom channels (BETA)',
        badge: 'BETA'
      }, {
        position: 2,
        url: '/conversations/inbox-and-messages',
        label: 'Conversations inbox and messages APIs (BETA)'
      }, {
        label: 'mobile-chat-sdk',
        items: [{
          url: '/conversations/mobile-chat-sdk/android',
          label: 'Integrate the HubSpot mobile chat SDK into your Android app (BETA)'
        }, {
          url: '/conversations/mobile-chat-sdk/ios',
          label: 'Integrate the HubSpot mobile chat SDK into your iOS app (BETA)'
        }],
        dir: 'mobile-chat-sdk'
      }, {
        url: '/conversations/visitor-identification',
        label: 'Visitor Identification'
      }, {
        label: 'working-with-chatbots',
        items: [{
          url: '/conversations/working-with-chatbots/code-snippets',
          label: 'Conversations | Running code snippets in bots'
        }, {
          url: '/conversations/working-with-chatbots/webhooks',
          label: 'Conversations | Webhooks in bots'
        }],
        dir: 'working-with-chatbots'
      }],
      dir: 'conversations',
      position: 6
    }, {
      label: 'library',
      items: [{
        url: '/library/files',
        label: 'CMS API | File Manager'
      }, {
        url: '/library/meetings',
        label: 'Library | Meetings'
      }],
      dir: 'library',
      position: 8
    }, {
      label: 'crm',
      items: [{
        position: 1,
        url: '/crm/understanding-the-crm',
        label: 'Understanding the CRM APIs'
      }, {
        position: 2,
        url: '/crm/using-object-apis',
        label: 'Using Object APIs'
      }, {
        label: 'associations',
        items: [{
          position: 1,
          url: '/crm/associations/associations-v3',
          label: 'CRM API | Associations'
        }, {
          url: '/crm/associations/associations-v4',
          label: 'CRM API |  Associations v4'
        }],
        dir: 'associations',
        position: 3
      }, {
        label: 'commerce',
        items: [{
          url: '/crm/commerce/carts',
          label: 'CRM API | Carts'
        }, {
          url: '/crm/commerce/discounts',
          label: 'CRM API | Discounts'
        }, {
          url: '/crm/commerce/fees',
          label: 'CRM API | Fees'
        }, {
          url: '/crm/commerce/invoices',
          label: 'CRM API | Invoices'
        }, {
          url: '/crm/commerce/orders',
          label: 'CRM API | Orders'
        }, {
          url: '/crm/commerce/quotes',
          label: 'CRM API | Quotes'
        }, {
          url: '/crm/commerce/taxes',
          label: 'CRM API | Taxes'
        }, {
          url: '/crm/commerce/payments',
          label: 'Payments API'
        }, {
          url: '/crm/commerce/subscriptions',
          label: 'Subscriptions API'
        }],
        dir: 'commerce',
        position: 4
      }, {
        label: 'engagements',
        items: [{
          position: 1,
          url: '/crm/engagements/engagement-details',
          label: 'Engagements API Overview'
        }, {
          url: '/crm/engagements/calls',
          label: 'Engagements | Calls'
        }, {
          url: '/crm/engagements/communications',
          label: 'Engagements | Communications'
        }, {
          url: '/crm/engagements/email',
          label: 'Engagements | Email'
        }, {
          url: '/crm/engagements/meetings',
          label: 'Engagements | Meetings'
        }, {
          url: '/crm/engagements/notes',
          label: 'Engagements | Notes'
        }, {
          url: '/crm/engagements/postal-mail',
          label: 'Engagements | Postal Mail'
        }, {
          url: '/crm/engagements/tasks',
          label: 'Engagements | Tasks'
        }],
        dir: 'engagements',
        position: 5
      }, {
        label: 'extensions',
        items: [{
          position: 1,
          url: '/crm/extensions/calling-sdk',
          label: 'CRM API | Calling extensions SDK'
        }, {
          position: 2,
          url: '/crm/extensions/crm-cards',
          label: 'CRM API | CRM cards'
        }, {
          position: 3,
          url: '/crm/extensions/timeline',
          label: 'CRM API | Timeline events'
        }, {
          url: '/crm/extensions/third-party-calling',
          label: 'CRM API | Set up third-party calling in help desk (BETA)',
          badge: 'BETA'
        }, {
          url: '/crm/extensions/video-conferencing',
          label: 'Video conference extension'
        }],
        dir: 'extensions',
        position: 6
      }, {
        position: 7,
        url: '/crm/exports',
        label: 'Exports'
      }, {
        position: 8,
        url: '/crm/imports',
        label: 'CRM API | Imports'
      }, {
        label: 'lists',
        items: [{
          position: 1,
          url: '/crm/lists/overview',
          label: 'Lists API'
        }, {
          url: '/crm/lists/lists-filters',
          label: 'List filters overview'
        }],
        dir: 'lists',
        position: 9
      }, {
        label: 'objects',
        items: [{
          url: '/crm/objects/custom-objects',
          label: 'CRM | custom objects'
        }, {
          url: '/crm/objects/companies',
          label: 'CRM API | Companies'
        }, {
          url: '/crm/objects/contacts',
          label: 'CRM API | Contacts'
        }, {
          url: '/crm/objects/deal-splits',
          label: 'CRM API | Deal splits',
          badge: 'BETA'
        }, {
          url: '/crm/objects/deals',
          label: 'CRM API | Deals'
        }, {
          url: '/crm/objects/feedback-submissions',
          label: 'CRM API | Feedback Submissions'
        }, {
          url: '/crm/objects/goals',
          label: 'CRM API | Goals',
          badge: 'NEW'
        }, {
          url: '/crm/objects/leads',
          label: 'CRM API | Leads'
        }, {
          url: '/crm/objects/line-items',
          label: 'CRM API | Line items'
        }, {
          url: '/crm/objects/products',
          label: 'CRM API | Products'
        }, {
          url: '/crm/objects/tickets',
          label: 'CRM API | Tickets'
        }],
        dir: 'objects',
        position: 10
      }, {
        position: 11,
        url: '/crm/embed',
        label: 'CRM API | HubSpot CRM Embed'
      }, {
        url: '/crm/owners',
        label: 'CRM API | Owners'
      }, {
        url: '/crm/pipelines',
        label: 'CRM API | Pipelines'
      }, {
        url: '/crm/properties',
        label: 'CRM API | Properties'
      }, {
        url: '/crm/search',
        label: 'CRM API | Search'
      }, {
        url: '/crm/limits-tracking',
        label: 'Limits tracking'
      }, {
        url: '/crm/object-library',
        label: 'Object library'
      }],
      dir: 'crm',
      position: 9
    }, {
      label: 'marketing',
      items: [{
        position: 1,
        url: '/marketing/campaigns',
        label: 'Marketing API | Campaigns '
      }, {
        label: 'emails',
        items: [{
          url: '/marketing/emails/marketing-emails',
          label: 'Marketing API | Marketing Email (BETA)'
        }, {
          url: '/marketing/emails/single-send-api',
          label: 'Marketing API | Single send API'
        }, {
          url: '/marketing/emails/transactional-emails',
          label: 'Marketing API | Transactional Emails '
        }],
        dir: 'emails'
      }, {
        url: '/marketing/forms',
        label: 'Marketing API | Forms '
      }, {
        url: '/marketing/marketing-events',
        label: 'Marketing API | Marketing Events'
      }, {
        url: '/marketing/subscriptions-preferences',
        label: 'Marketing API | Subscriptions Preferences'
      }, {
        url: '/marketing/subscriptions',
        label: 'Marketing API | Subscriptions v4'
      }],
      dir: 'marketing',
      position: 9
    }, {
      label: 'settings',
      items: [{
        url: '/settings/account-activity-api',
        label: 'Account Activity API'
      }, {
        url: '/settings/account-information-api',
        label: 'Account Information API'
      }, {
        url: '/settings/business-units-api',
        label: 'Business Units API'
      }, {
        url: '/settings/currencies',
        label: 'Settings API | Currencies',
        badge: 'NEW'
      }, {
        label: 'users',
        items: [{
          url: '/settings/users/user-details',
          label: 'Account | Users API'
        }, {
          url: '/settings/users/user-provisioning',
          label: 'Settings API | User Provisioning'
        }],
        dir: 'users'
      }],
      dir: 'settings',
      position: 10
    }, {
      label: 'legal',
      items: [{
        url: 'https://legal.hubspot.com/hubspot-developer-policy',
        position: 1,
        label: 'Developer Policy',
        external: true
      }, {
        url: 'https://legal.hubspot.com/developer-terms',
        position: 2,
        label: 'Developer Terms',
        external: true
      }, {
        url: 'https://legal.hubspot.com/acceptable-use',
        position: 3,
        label: 'Acceptable Use',
        external: true
      }],
      dir: 'legal'
    }]
  },
  '/guides/cms': {
    items: [{
      position: 1,
      url: '/overview',
      label: 'HubSpot CMS overview'
    }, {
      label: 'tools',
      items: [{
        url: '/tools/design-manager',
        label: 'Design Manager'
      }, {
        url: '/tools/local-development-cli',
        label: 'HubSpot CLI commands'
      }, {
        url: '/tools/personal-access-key',
        label: 'Personal CMS Access Keys'
      }],
      dir: 'tools',
      position: 2
    }, {
      position: 3,
      url: '/quickstart',
      label: 'Quickstart guide to developing on the HubSpot CMS'
    }, {
      label: 'setup',
      items: [{
        url: '/setup/getting-started-with-local-development',
        label: 'Getting started with local development'
      }, {
        url: '/setup/js-frameworks',
        label: 'How to use JavaScript frameworks on HubSpot'
      }, {
        url: '/setup/install-and-use-hubspot-code-extension',
        label: 'Install and use the HubSpot VS Code extension'
      }, {
        url: '/setup/creating-an-efficient-development-workflow',
        label: 'Optimize your HubSpot development workflow'
      }, {
        url: '/setup/github-integration',
        label: 'Set up continuous integration with a GitHub repository using GitHub Actions'
      }],
      dir: 'setup',
      position: 4
    }, {
      label: 'storage',
      items: [{
        url: '/storage/file-manager',
        label: 'File Manager'
      }, {
        label: 'hubdb',
        items: [{
          position: 1,
          url: '/storage/hubdb/overview',
          label: 'HubDB'
        }, {
          url: '/storage/hubdb/location-based-pages',
          label: 'Build location-based pages with HubDB'
        }],
        dir: 'hubdb'
      }],
      dir: 'storage',
      position: 5
    }, {
      label: 'content',
      items: [{
        position: 1,
        url: '/content/overview',
        label: 'CMS building blocks overview'
      }, {
        label: 'performance',
        items: [{
          url: '/content/performance/overview',
          label: 'CDN, security, and performance overview'
        }, {
          url: '/content/performance/lazy-loading',
          label: 'Lazy loading assets for performance'
        }, {
          url: '/content/performance/speed',
          label: 'Optimizing your HubSpot CMS site for speed'
        }, {
          url: '/content/performance/prerendering',
          label: 'Prerendering'
        }, {
          url: '/content/performance/reverse-proxy-support',
          label: 'Use a reverse proxy with HubSpot'
        }],
        dir: 'performance',
        position: 2,
        breadcrumbOverride: 'cdnSecurityAndPerformance'
      }, {
        label: 'themes',
        items: [{
          position: 1,
          url: '/content/themes/overview',
          label: 'Theme overview'
        }, {
          position: 2,
          url: '/content/themes/getting-started',
          label: 'Getting started with themes'
        }, {
          position: 3,
          url: '/content/themes/hubspot-cms-boilerplate',
          label: 'The HubSpot CMS Boilerplate'
        }, {
          url: '/content/themes/build-and-deploy-with-projects',
          label: 'Build a CMS theme using projects'
        }, {
          url: '/content/themes/child-themes',
          label: 'Child Themes'
        }, {
          url: '/content/themes/default-themes',
          label: 'Default Themes'
        }, {
          url: '/content/themes/quotes-themes',
          label: 'Getting started from the CMS quotes theme'
        }, {
          url: '/content/themes/responsive-breakpoints',
          label: 'Responsive Breakpoints for Themes'
        }],
        dir: 'themes',
        position: 3
      }, {
        label: 'templates',
        items: [{
          position: 1,
          url: '/content/templates/overview',
          label: 'Templates overview'
        }, {
          position: 2,
          url: '/content/templates/default-email-modules',
          label: 'Update email templates to use email-specific default modules'
        }, {
          label: 'types',
          items: [{
            url: '/content/templates/types/blog',
            label: 'Blog templates'
          }, {
            url: '/content/templates/types/quotes',
            label: 'Custom quote templates'
          }, {
            url: '/content/templates/types/drag-and-drop-templates',
            label: 'Drag and Drop Templates'
          }, {
            url: '/content/templates/types/email-template-markup',
            label: 'Email template markup'
          }, {
            url: '/content/templates/types/html-hubl-templates',
            label: 'HTML + HubL Templates'
          }],
          dir: 'types',
          position: 3
        }, {
          label: 'drag-and-drop',
          items: [{
            position: 1,
            url: '/content/templates/drag-and-drop/overview',
            label: 'Drag and Drop areas overview'
          }, {
            position: 2,
            url: '/content/templates/drag-and-drop/custom-email-template',
            label: 'Specify drag and drop areas in a custom email template'
          }, {
            url: '/content/templates/drag-and-drop/tutorial',
            label: 'Creating a drag and drop area'
          }, {
            url: '/content/templates/drag-and-drop/sections',
            label: 'Sections'
          }],
          dir: 'drag-and-drop'
        }],
        dir: 'templates',
        position: 4
      }, {
        label: 'modules',
        items: [{
          position: 1,
          url: '/content/modules/overview',
          label: 'Modules Overview'
        }, {
          position: 2,
          url: '/content/modules/quickstart',
          label: 'Getting started with Modules'
        }, {
          url: '/content/modules/build-modules-and-partials-with-react',
          label: 'Build CMS modules with React'
        }, {
          url: '/content/modules/hide-modules-and-sections',
          label: 'Hide modules and sections in the page editor'
        }],
        dir: 'modules',
        position: 5
      }, {
        label: 'fields',
        items: [{
          position: 1,
          url: '/content/fields/overview',
          label: 'Module and Theme Fields Overview'
        }, {
          position: 2,
          url: '/content/fields/best-practices',
          label: 'Module and Theme Field Best Practices'
        }, {
          url: '/content/fields/custom-fonts',
          label: 'Add custom fonts to a theme'
        }, {
          url: '/content/fields/alias-mapping',
          label: 'Alias mapping'
        }, {
          url: '/content/fields/brand-and-settings-inheritance',
          label: 'Brand settings inheritance'
        }, {
          url: '/content/fields/oembed',
          label: 'oEmbed'
        }, {
          url: '/content/fields/write-fields-using-javascript',
          label: 'Write module and theme fields using JavaScript'
        }],
        dir: 'fields',
        position: 6
      }, {
        label: 'data-driven-content',
        items: [{
          position: 1,
          url: '/content/data-driven-content/crm-objects',
          label: 'CRM objects in CMS pages'
        }, {
          label: 'dynamic-pages',
          items: [{
            position: 1,
            url: '/content/data-driven-content/dynamic-pages/overview',
            label: 'Dynamic pages overview'
          }, {
            url: '/content/data-driven-content/dynamic-pages/crm-objects',
            label: 'Build dynamic pages using CRM objects'
          }, {
            url: '/content/data-driven-content/dynamic-pages/hubdb',
            label: 'Build dynamic pages using HubDB'
          }, {
            url: '/content/data-driven-content/dynamic-pages/video',
            label: 'How to add videos to dynamic pages in HubDB'
          }, {
            url: '/content/data-driven-content/dynamic-pages/dynamic-team-member-page',
            label: 'How To build a Dynamic Team Member Page with HubDB'
          }, {
            url: '/content/data-driven-content/dynamic-pages/multilevel',
            label: 'How to build multilevel dynamic pages with HubDB'
          }],
          dir: 'dynamic-pages',
          position: 2
        }, {
          label: 'graphql',
          items: [{
            url: '/content/data-driven-content/graphql/build-a-recruiting-agency-using-graphql',
            label: 'Build a recruiting agency website using GraphQL (BETA)',
            badge: 'BETA'
          }, {
            url: '/content/data-driven-content/graphql/query-hubspot-data-using-graphql',
            label: 'Query HubSpot data using GraphQL'
          }, {
            url: '/content/data-driven-content/graphql/use-graphql-data-in-your-website-pages',
            label: 'Use data from a GraphQL query in your website pages'
          }],
          dir: 'graphql',
          position: 3
        }, {
          label: 'serverless-functions',
          items: [{
            position: 1,
            url: '/content/data-driven-content/serverless-functions/overview',
            label: 'Serverless Functions Overview'
          }, {
            url: '/content/data-driven-content/serverless-functions/getting-started-with-serverless-functions',
            label: 'Getting started with serverless functions'
          }],
          dir: 'serverless-functions',
          position: 4
        }, {
          url: '/content/data-driven-content/emails-with-programmable-content',
          label: 'Create emails with programmable content'
        }],
        dir: 'data-driven-content',
        position: 7
      }, {
        url: '/content/accessibility',
        label: 'Accessibility'
      }, {
        url: '/content/content-search',
        label: 'Content Search'
      }, {
        url: '/content/content-staging',
        label: 'Content Staging'
      }, {
        url: '/content/global-content',
        label: 'Global Content'
      }, {
        url: '/content/provide-a-good-editor-experience',
        label: 'How to provide a good experience in the page editor'
      }, {
        url: '/content/forms',
        label: 'HubSpot forms'
      }, {
        label: 'memberships',
        items: [{
          position: 1,
          url: '/content/memberships/overview',
          label: 'Memberships'
        }, {
          url: '/content/memberships/google-sign-in',
          label: 'Google Sign-In for Memberships'
        }, {
          url: '/content/memberships/social',
          label: 'How to add social login for membership pages'
        }, {
          url: '/content/memberships/microsoft-sign-in',
          label: 'Microsoft Sign-In for Memberships'
        }, {
          url: '/content/memberships/sso',
          label: 'SSO for Memberships'
        }],
        dir: 'memberships'
      }, {
        url: '/content/menus-and-navigation',
        label: 'Menus and Navigation'
      }, {
        url: '/content/multi-language-content',
        label: 'Multi-language Content'
      }, {
        url: '/content/website-settings',
        label: 'Website Settings'
      }],
      dir: 'content'
    }, {
      label: 'debugging',
      items: [{
        url: '/debugging/code-alerts',
        label: 'Code Alerts'
      }, {
        url: '/debugging/troubleshooting',
        label: 'Debugging methods and error types'
      }],
      dir: 'debugging'
    }, {
      label: 'improve-older-sites',
      items: [{
        url: '/improve-older-sites/convert-wordpress-theme-to-hubspot',
        label: 'Convert a WordPress theme to a CMS Hub theme'
      }, {
        url: '/improve-older-sites/add-theme-features-to-existing-sites',
        label: 'How to add theme capabilities to an existing HubSpot CMS website'
      }, {
        url: '/improve-older-sites/upgrade-jquery',
        label: 'How to upgrade to the latest version of jQuery'
      }],
      dir: 'improve-older-sites'
    }, {
      label: 'marketplace',
      items: [{
        url: '/marketplace/template-guidelines',
        label: 'HubSpot Template Marketplace Guidelines'
      }, {
        url: '/marketplace/general-requirements',
        label: 'HubSpot Template Marketplace listing page requirements'
      }, {
        url: '/marketplace/template-policies',
        label: 'HubSpot Template Marketplace policies'
      }, {
        url: '/marketplace/theme-requirements',
        label: 'HubSpot Template Marketplace theme requirements'
      }, {
        url: '/marketplace/listing-templates',
        label: 'List and update templates in the HubSpot Template Marketplace'
      }, {
        url: '/marketplace/module-requirements',
        label: 'Template Marketplace | Module requirements'
      }],
      dir: 'marketplace'
    }]
  },
  '/guides/crm': {
    items: [{
      url: '/setup',
      label: 'Developer projects setup guide (BETA)'
    }, {
      label: 'developer-projects',
      items: [{
        url: '/developer-projects/create-a-project',
        label: 'Create a project (BETA)'
      }, {
        url: '/developer-projects/project-cli-commands',
        label: 'Developer projects CLI reference (BETA)'
      }, {
        url: '/developer-projects/link-a-github-repository-to-a-project',
        label: 'Link a GitHub repository to a project (BETA)'
      }, {
        url: '/developer-projects/platform-versioning',
        label: 'Project platform versioning (BETA)'
      }],
      dir: 'developer-projects'
    }, {
      label: 'private-apps',
      items: [{
        url: '/private-apps/creating-private-apps',
        label: 'Creating private apps with projects (BETA)'
      }, {
        url: '/private-apps/serverless-functions',
        label: 'Fetching data with serverless functions (BETA)'
      }, {
        url: '/private-apps/quickstart',
        label: 'UI extensions for private apps quickstart (BETA)'
      }],
      dir: 'private-apps'
    }, {
      label: 'public-apps',
      items: [{
        position: 1,
        url: '/public-apps/overview',
        label: 'Build UI extensions for public apps (BETA)'
      }, {
        url: '/public-apps/creating-public-apps',
        label: 'Creating public apps using projects (BETA)'
      }, {
        url: '/public-apps/fetching-data',
        label: 'Fetching data for public apps (BETA)'
      }, {
        url: '/public-apps/migrate-a-public-app-to-projects',
        label: 'Migrate a public app to the developer projects framework (BETA)'
      }, {
        url: '/public-apps/quickstart',
        label: 'UI extensions for public apps quickstart'
      }, {
        url: '/public-apps/webhooks',
        label: 'Working with webhooks (BETA)'
      }],
      dir: 'public-apps'
    }, {
      url: '/overview',
      label: 'UI customization overview (BETA)'
    }, {
      label: 'ui-extensions',
      items: [{
        url: '/ui-extensions/create',
        label: 'Create UI extensions with React (BETA)'
      }, {
        url: '/ui-extensions/local-development',
        label: 'Local development for UI extensions (BETA)'
      }, {
        label: 'sample-extensions',
        items: [{
          url: '/ui-extensions/sample-extensions/deals-summary-tutorial',
          label: 'Deals summary tutorial'
        }, {
          url: '/ui-extensions/sample-extensions/overview',
          label: 'Sample UI extensions'
        }],
        dir: 'sample-extensions'
      }, {
        url: '/ui-extensions/overview',
        label: 'UI Extensions overview (BETA)'
      }, {
        url: '/ui-extensions/sdk',
        label: 'UI extensions SDK (BETA)'
      }],
      dir: 'ui-extensions'
    }]
  },
  '/guides/apps': {
    items: [{
      position: 1,
      url: '/overview',
      label: 'Building apps overview'
    }, {
      label: 'authentication',
      items: [{
        position: 1,
        url: '/authentication/intro-to-auth',
        label: 'HubSpot APIs | Authentication methods on HubSpot'
      }, {
        position: 2,
        url: '/authentication/validating-requests',
        label: 'Webhooks | Validating Requests'
      }, {
        position: 3,
        url: '/authentication/working-with-oauth',
        label: 'Working with OAuth'
      }, {
        position: 4,
        url: '/authentication/oauth-quickstart-guide',
        label: 'Working with OAuth | OAuth Quickstart Guide'
      }, {
        url: '/authentication/scopes',
        label: 'Scopes'
      }],
      dir: 'authentication',
      position: 2
    }, {
      label: 'private-apps',
      items: [{
        position: 1,
        url: '/private-apps/overview',
        label: 'Private apps'
      }, {
        url: '/private-apps/create-and-edit-webhook-subscriptions-in-private-apps',
        label: 'Create and edit webhook subscriptions in private apps'
      }, {
        url: '/private-apps/migrate-an-api-key-integration-to-a-private-app',
        label: 'Migrate an API key integration to a private app'
      }],
      dir: 'private-apps',
      position: 3
    }, {
      label: 'public-apps',
      items: [{
        position: 1,
        url: '/public-apps/overview',
        label: 'Public apps'
      }, {
        url: '/public-apps/create-generic-webhook-subscriptions',
        label: 'Create generic webhook subscriptions (BETA)',
        badge: 'BETA'
      }, {
        url: '/public-apps/create-an-app-settings-page',
        label: 'Developer tools | Create a settings page for your app'
      }, {
        url: '/public-apps/import-users-into-any-hubspot-account',
        label: 'Import users into any HubSpot account from an external app'
      }, {
        url: '/public-apps/share-hubspot-reports-with-your-app',
        label: 'Use your installed app to share HubSpot reports to channels and users (BETA)'
      }],
      dir: 'public-apps',
      position: 4
    }, {
      label: 'extensions',
      items: [{
        position: 1,
        url: '/extensions/overview',
        label: 'Extensions overview'
      }, {
        label: 'calling-extensions',
        items: [{
          url: '/extensions/calling-extensions/receive-incoming-calls',
          label: 'CRM API | Receive calls in HubSpot when using calling apps (BETA)'
        }, {
          url: '/extensions/calling-extensions/recordings-and-transcriptions',
          label: 'Extending the CRM | Recordings and transcriptions'
        }],
        dir: 'calling-extensions'
      }],
      dir: 'extensions',
      position: 5
    }, {
      label: 'api-usage',
      items: [{
        url: '/api-usage/breaking-change-definition',
        label: 'Breaking Changes on the HubSpot Platform'
      }, {
        url: '/api-usage/usage-details',
        label: 'HubSpot APIs | Usage guidelines'
      }],
      dir: 'api-usage'
    }, {
      label: 'marketplace',
      items: [{
        url: '/marketplace/certification-requirements',
        label: 'App Marketplace | App certification requirements'
      }, {
        url: '/marketplace/app-marketplace-listing-requirements',
        label: 'App Marketplace | App listing requirements'
      }, {
        url: '/marketplace/listing-your-app',
        label: 'App Marketplace | Listing your app'
      }, {
        url: '/marketplace/testing-credentials',
        label: 'App Marketplace | Provide testing credentials for your app'
      }, {
        url: '/marketplace/understand-app-categories',
        label: 'App Marketplace | Understand app categories'
      }, {
        url: '/marketplace/applying-for-app-certification',
        label: 'Applying for app certification'
      }, {
        url: '/marketplace/measuring-app-performance',
        label: 'Measuring app performance'
      }],
      dir: 'marketplace'
    }]
  }
};