import PropTypes from 'prop-types';
import styled from 'styled-components';
import { toPx } from '../utils/Styles';
import { EXPANDABLE_DARK_PADDING_TOP, EXPANDABLE_PADDING_BOTTOM, EXPANDABLE_PADDING_LEFT, EXPANDABLE_PADDING_TOP, EXPANDABLE_PADDING_X } from './ExpandableConstants';
const INDENT_OPTIONS = {
  default: EXPANDABLE_PADDING_LEFT,
  compact: EXPANDABLE_PADDING_X,
  none: 0
};
const UIExpandableContents = styled.div.withConfig({
  displayName: "UIExpandableContents",
  componentId: "oundzn-0"
})(["padding-bottom:", ";padding-left:", ";padding-right:", ";padding-top:", ";>:last-child{margin-bottom:0;}"], EXPANDABLE_PADDING_BOTTOM, ({
  indentation = 'default'
}) => typeof indentation === 'number' ? toPx(indentation) : INDENT_OPTIONS[indentation], ({
  indentation
}) => indentation === 'none' ? 0 : EXPANDABLE_PADDING_X, ({
  indentation,
  use
}) => {
  if (indentation === 'none') return 0;
  return use === 'enclosed' ? EXPANDABLE_DARK_PADDING_TOP : EXPANDABLE_PADDING_TOP;
});
UIExpandableContents.propTypes = {
  children: PropTypes.node,
  indentation: PropTypes.oneOfType([PropTypes.number, PropTypes.oneOf(['default', 'compact', 'none'])])
};
UIExpandableContents.defaultProps = {
  indentation: 'default'
};
UIExpandableContents.displayName = 'UIExpandableContents';
export default UIExpandableContents;