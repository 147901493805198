import { apiReferenceLabels } from './translations/labels';
import { breadcrumbs } from './translations/breadcrumbs';

/*
 * This type requires the label to be a valid api reference label key
 * This is used for types where both the label and breadcrumb translation are coming from `developer-docs-ui`
 */

/**
 * This type allows the label to be any string, but the breadcrumb must be a valid breadcrumb key.
 * This is used for types where the label translation is coming from `developer-docs-source` but the breadcrumb translation is coming from `developer-docs-ui`
 */

// iteration that requires ending in a label and only one

export function assertIsBreadcrumbKeyArray(array) {
  for (const key of array) {
    if (!isBreadcrumb(key)) {
      throw new Error(`Invalid breadcrumb key: ${key}`);
    }
  }
}
export function isBreadcrumb(key) {
  const keyExists = (key in breadcrumbs);
  if (!keyExists) {
    console.error(`Invalid breadcrumb key: ${key}`);
  }
  return keyExists;
}
export function isApiReferenceLabel(label) {
  return label in apiReferenceLabels;
}