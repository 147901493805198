import enviro from 'enviro';
export const stringToId = str => encodeURIComponent(str.toLocaleLowerCase()
// replace :: and ' with space, which will be replaced with -
.replace(/::|'/g, ' ')
// remove : and ()
.replace(/[:()]/g, '').split(' ').join('-'));

/**
 * The absolute base path off `(br\.)?developers\.hubspot\.(com|es|fr|jp|de){1}`.
 */
export const proxiedSubdomainBasePath = enviro.isQa() && enviro.deployed() ? '/docs' : '/beta-docs';