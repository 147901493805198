import { useEffect } from 'react';

/**
 * Scrolls the page to the new hash when the URL hash changes.
 * If no hash is present in the URL, it scrolls to the element with the provided header ID.
 * @param headerId The ID of the header element to scroll to when no hash is present in the URL.
 */
export const useScrollHashIntoView = (headerId, contentLoaded) => {
  // if the hash is a format like #use-apis-and-build-integrations?uuid=1914cd86-e317-4471-9d64-3f359e316f44 or #use-apis-and-build-integrations, we want to scroll to the hash use-apis-and-build-integrations
  const match = window.location.hash.match(/^#([^?]+)/);
  const hash = match ? match[1] : '';
  useEffect(() => {
    const id = hash && hash !== '' ? hash : headerId;
    if (!id) {
      return;
    }
    const el = document.getElementById(id);
    if (el) {
      el.scrollIntoView();
    }
    // Content loaded is used to trigger the scroll after the content is loaded
  }, [hash, headerId, contentLoaded]);
};