import { useEffect, useState } from 'react';
import { unescape } from 'underscore';
import getLangLocale from 'I18n/utils/getLangLocale';
import enviro from 'enviro';
import { getSubdomainFromLocale } from '../lib/locale/utils';
import { proxiedSubdomainBasePath } from '../utils/urls';
const setOpenGraphTag = (attrValue, content) => {
  // Whether to set meta[property] or meta[name]
  const attrName = attrValue.startsWith('og:') ? 'property' : 'name';
  const existingEl = document.head.querySelector(`meta[${attrName}="${attrValue}"]`);
  if (existingEl) {
    existingEl.setAttribute('content', content);
  } else {
    // Add tag if it doesn't exist
    const newEl = document.createElement('meta');
    newEl.setAttribute(attrName, attrValue);
    newEl.content = content;
    document.head.appendChild(newEl);
  }
};

/**
 * Sets `noindex` tag to prevent indexing by crawlers.
 */
const setNoIndex = noIndex => {
  const existingEl = document.head.querySelector(`meta[content="noindex"]`);
  if (noIndex) {
    if (existingEl) {
      return;
    }
    const newEl = document.createElement('meta');
    newEl.content = 'noindex';
    newEl.name = 'robots';
    document.head.appendChild(newEl);
  } else {
    if (existingEl) {
      existingEl.remove();
    }
  }
};
const setCanonical = url => {
  let canonicalEl = document.head.querySelector(`link[rel="canonical"]`);
  if (canonicalEl) {
    // Set canonical tag
    canonicalEl.setAttribute('href', url);
  } else {
    // Add canonical tag if it doesn't exist
    canonicalEl = document.createElement('link');
    canonicalEl.rel = 'canonical';
    canonicalEl.href = url;
    document.head.appendChild(canonicalEl);
  }
};
/**
 * Replaces the build-time-rendered meta titles, descriptions, and indexing
 * settings with metadata specific to a given route.
 */
export const useMetadata = metadata => {
  const [meta, setMeta] = useState(metadata);
  useEffect(() => {
    /**
     * Title without HTML entities.
     * @see https://issues.hubspotcentral.com/browse/DGS-316
     */
    const unescapedTitle = unescape(metadata.title);

    /**
     * Description without HTML entities.
     * @see https://issues.hubspotcentral.com/browse/DGS-316
     */
    const unescapedDescription = unescape(metadata.description);

    // Title metadata
    document.title = unescapedTitle;
    setOpenGraphTag('og:title', unescapedTitle);
    setOpenGraphTag('twitter:title', unescapedTitle);

    // Description metadata
    setOpenGraphTag('description', unescapedDescription);
    setOpenGraphTag('og:description', unescapedDescription);
    setOpenGraphTag('twitter:description', unescapedDescription);

    // OpenGraph image (referred to as Featured Image in the CMS)
    if (metadata.featuredImage) {
      setOpenGraphTag('og:image', metadata.featuredImage);
      setOpenGraphTag('og:image:alt', metadata.featuredImageAltText || unescapedTitle);
    } else {
      // Reset (not really necessary given Prerender caching)
      setOpenGraphTag('og:image', '//static.hsappstatic.net/developer-docs-ui/static-1.10205/img/og-image.png');
      setOpenGraphTag('og:image:alt', unescapedTitle);
    }
    setNoIndex(metadata.noIndex);

    // URL metadata for SEO and sharing
    const {
      location: {
        pathname
      }
    } = window;
    const locale = getLangLocale();
    const subdomain = getSubdomainFromLocale(locale, enviro.isQa());

    /**
     * Pathname excluding locale route (e.g. `developer-docs-app-en-us`)
     * and trailing slash.
     */
    const computedPathName = pathname.replace(/\/developer-docs-app-.{5}/g, '').replace(proxiedSubdomainBasePath, '').replace(/\/$/, '');

    /**
     * Proxied URL off `*.developers.hubspot.*`.
     */
    const proxiedUrl = `${subdomain}${proxiedSubdomainBasePath}${computedPathName}`;

    // Set canonical
    setCanonical(proxiedUrl);

    //Set OpenGraph URL
    setOpenGraphTag('og:url', proxiedUrl);
    if (metadata.isContentLoaded) {
      // Set Prerender variable after title and metadata set @see https://docs.prerender.io/docs/25-empty-or-partially-rendered-pages
      window.prerenderReady = true;
    }
    // TODO: Hreflangs
  }, [metadata]);
  return [meta, setMeta];
};
export default useMetadata;