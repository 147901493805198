import I18n from 'I18n';
import { PageTypes, Paths } from '../../../components/RoutesPublic';
import { addLabelAndVerifyBadge } from '../navUtils';
import { navigation } from 'developer-docs-source/navigation';

/**
 * 'Get Started' nav config.
 *
 */
export const getStartedNavConfig = () => {
  const commonPath = Paths.GETTING_STARTED;
  const nav = navigation[commonPath];
  const navigationItems = nav.items.map(item => addLabelAndVerifyBadge(item, commonPath));
  const config = {
    commonPath,
    locatorPageType: PageTypes.GETTING_STARTED,
    sectionLabel: I18n.text('developer-docs-ui.navigation.getStarted.primaryNavLinkLabel'),
    items: navigationItems
  };
  return config;
};