import I18n from 'I18n';
export const apiReferenceLabels = {
  deprecated: () => I18n.text('developer-docs-shared-components.navigation.labels.deprecated'),
  getSetUp: () => I18n.text('developer-docs-shared-components.navigation.labels.getSetUp'),
  'social-media': () => I18n.text('developer-docs-shared-components.navigation.labels.socialMedia'),
  leads: () => I18n.text('developer-docs-shared-components.navigation.labels.leads'),
  'contacts-timeline-embed': () => I18n.text('developer-docs-shared-components.navigation.labels.contactsTimelineEmbed'),
  overview: () => I18n.text('developer-docs-shared-components.navigation.labels.overview'),
  quotes: () => I18n.text('developer-docs-shared-components.navigation.labels.quotes'),
  modules: () => I18n.text('developer-docs-shared-components.navigation.labels.modules'),
  hubdb: () => 'HubDB',
  'custom-event-definitions': () => I18n.text('developer-docs-shared-components.navigation.labels.customEventDefinitions'),
  reporting: () => I18n.text('developer-docs-shared-components.navigation.labels.reporting'),
  'event-analytics': () => I18n.text('developer-docs-shared-components.navigation.labels.eventAnalytics'),
  'custom-event-completions': () => I18n.text('developer-docs-shared-components.navigation.labels.customEventCompletions'),
  'email-analytics': () => I18n.text('developer-docs-shared-components.navigation.labels.emailAnalytics'),
  'custom-workflow-actions': () => I18n.text('developer-docs-shared-components.navigation.labels.customWorkflowActions'),
  sequences: () => I18n.text('developer-docs-shared-components.navigation.labels.sequences'),
  'create-manage-workflows': () => I18n.text('developer-docs-shared-components.navigation.labels.createManageWorkflows'),
  'blog-authors': () => I18n.text('developer-docs-shared-components.navigation.labels.blogAuthors'),
  'blog-posts': () => I18n.text('developer-docs-shared-components.navigation.labels.blogPosts'),
  'blog-tags': () => I18n.text('developer-docs-shared-components.navigation.labels.blogTags'),
  'content-audit': () => I18n.text('developer-docs-shared-components.navigation.labels.contentAudit'),
  domains: () => I18n.text('developer-docs-shared-components.navigation.labels.domains'),
  'media-bridge': () => I18n.text('developer-docs-shared-components.navigation.labels.mediaBridge'),
  pages: () => I18n.text('developer-docs-shared-components.navigation.labels.pages'),
  'site-search': () => I18n.text('developer-docs-shared-components.navigation.labels.siteSearch'),
  'source-code': () => I18n.text('developer-docs-shared-components.navigation.labels.sourceCode'),
  'url-redirects': () => I18n.text('developer-docs-shared-components.navigation.labels.urlRedirects'),
  'google-consent-mode': () => I18n.text('developer-docs-shared-components.navigation.labels.implementGoogleConsentMode'),
  'tracking-code': () => I18n.text('developer-docs-shared-components.navigation.labels.trackingCode'),
  'cookie-banner-api': () => I18n.text('developer-docs-shared-components.navigation.labels.cookieBannerApi'),
  'custom-code-actions': () => I18n.text('developer-docs-shared-components.navigation.labels.customCodeActions'),
  'blog-details': () => I18n.text('developer-docs-shared-components.navigation.labels.blogDetails'),
  'blog-comments': () => I18n.text('developer-docs-shared-components.navigation.labels.blogComments'),
  'blog-topics': () => I18n.text('developer-docs-shared-components.navigation.labels.blogTopics'),
  layouts: () => I18n.text('developer-docs-shared-components.navigation.labels.layouts'),
  templates: () => I18n.text('developer-docs-shared-components.navigation.labels.templates'),
  'chat-widget-sdk': () => I18n.text('developer-docs-shared-components.navigation.labels.chatWidgetSdk'),
  'inbox-and-messages': () => I18n.text('developer-docs-shared-components.navigation.labels.inboxAndMessages'),
  'create-custom-channels': () => I18n.text('developer-docs-shared-components.navigation.labels.createCustomChannels'),
  'visitor-identification': () => I18n.text('developer-docs-shared-components.navigation.labels.visitorIdentification'),
  'association-details': () => I18n.text('developer-docs-shared-components.navigation.labels.associationDetails'),
  'associations-schema': () => I18n.text('developer-docs-shared-components.navigation.labels.associationsSchema'),
  carts: () => I18n.text('developer-docs-shared-components.navigation.labels.carts'),
  discounts: () => I18n.text('developer-docs-shared-components.navigation.labels.discounts'),
  fees: () => I18n.text('developer-docs-shared-components.navigation.labels.fees'),
  invoices: () => I18n.text('developer-docs-shared-components.navigation.labels.invoices'),
  orders: () => I18n.text('developer-docs-shared-components.navigation.labels.orders'),
  payments: () => I18n.text('developer-docs-shared-components.navigation.labels.payments'),
  subscriptions: () => I18n.text('developer-docs-shared-components.navigation.labels.subscriptions'),
  taxes: () => I18n.text('developer-docs-shared-components.navigation.labels.taxes'),
  'engagement-details': () => I18n.text('developer-docs-shared-components.navigation.labels.engagementDetails'),
  calls: () => I18n.text('developer-docs-shared-components.navigation.labels.calls'),
  communications: () => I18n.text('developer-docs-shared-components.navigation.labels.communications'),
  email: () => I18n.text('developer-docs-shared-components.navigation.labels.email'),
  meetings: () => I18n.text('developer-docs-shared-components.navigation.labels.meetings'),
  notes: () => I18n.text('developer-docs-shared-components.navigation.labels.notes'),
  'postal-mail': () => I18n.text('developer-docs-shared-components.navigation.labels.postalMail'),
  tasks: () => I18n.text('developer-docs-shared-components.navigation.labels.tasks'),
  'calling-sdk': () => I18n.text('developer-docs-shared-components.navigation.labels.callingSdk'),
  'crm-cards': () => I18n.text('developer-docs-shared-components.navigation.labels.crmCards'),
  'extension-details': () => I18n.text('developer-docs-shared-components.navigation.labels.extensionDetails'),
  timeline: () => I18n.text('developer-docs-shared-components.navigation.labels.timelineEvents'),
  'video-conferencing': () => I18n.text('developer-docs-shared-components.navigation.labels.videoConferencing'),
  exports: () => I18n.text('developer-docs-shared-components.navigation.labels.exportCrmData'),
  imports: () => I18n.text('developer-docs-shared-components.navigation.labels.importCrmData'),
  lists: () => I18n.text('developer-docs-shared-components.navigation.labels.lists'),
  companies: () => I18n.text('developer-docs-shared-components.navigation.labels.companies'),
  contacts: () => I18n.text('developer-docs-shared-components.navigation.labels.contacts'),
  objects: () => I18n.text('developer-docs-shared-components.navigation.labels.objects'),
  deals: () => I18n.text('developer-docs-shared-components.navigation.labels.deals'),
  'deal-splits': () => I18n.text('developer-docs-shared-components.navigation.labels.dealSplits'),
  'feedback-submissions': () => I18n.text('developer-docs-shared-components.navigation.labels.feedbackSubmissions'),
  'error-handling': () => I18n.text('developer-docs-shared-components.navigation.labels.errorHandling'),
  goals: () => I18n.text('developer-docs-shared-components.navigation.labels.goals'),
  'line-items': () => I18n.text('developer-docs-shared-components.navigation.labels.lineItems'),
  products: () => I18n.text('developer-docs-shared-components.navigation.labels.products'),
  tickets: () => I18n.text('developer-docs-shared-components.navigation.labels.tickets'),
  schemas: () => I18n.text('developer-docs-shared-components.navigation.labels.schemas'),
  owners: () => I18n.text('developer-docs-shared-components.navigation.labels.owners'),
  pipelines: () => I18n.text('developer-docs-shared-components.navigation.labels.pipelines'),
  properties: () => I18n.text('developer-docs-shared-components.navigation.labels.properties'),
  files: () => I18n.text('developer-docs-shared-components.navigation.labels.files'),
  forms: () => I18n.text('developer-docs-shared-components.navigation.labels.forms'),
  'marketing-events': () => I18n.text('developer-docs-shared-components.navigation.labels.marketingEvents'),
  'subscriptions-preferences': () => I18n.text('developer-docs-shared-components.navigation.labels.subscriptionsPreferences'),
  'transactional-emails': () => I18n.text('developer-docs-shared-components.navigation.labels.transactionalEmails'),
  'marketing-emails': () => I18n.text('developer-docs-shared-components.navigation.labels.marketingEmails'),
  'single-send-api': () => I18n.text('developer-docs-shared-components.navigation.labels.singleSendApi'),
  oauth: () => I18n.text('developer-docs-shared-components.navigation.labels.oauth'),
  'account-activity-api': () => I18n.text('developer-docs-shared-components.navigation.labels.accountActivityApi'),
  'account-information-api': () => I18n.text('developer-docs-shared-components.navigation.labels.accountInformationApi'),
  'business-units-api': () => I18n.text('developer-docs-shared-components.navigation.labels.businessUnitsApi'),
  currencies: () => I18n.text('developer-docs-shared-components.navigation.labels.currencies'),
  'user-provisioning': () => I18n.text('developer-docs-shared-components.navigation.labels.userProvisioning'),
  'user-details': () => I18n.text('developer-docs-shared-components.navigation.labels.userDetails'),
  webhooks: () => I18n.text('developer-docs-shared-components.navigation.labels.webhooks'),
  'limits-tracking': () => I18n.text('developer-docs-shared-components.navigation.labels.limitsTracking'),
  'feature-flags': () => I18n.text('developer-docs-shared-components.navigation.labels.featureFlags'),
  'object-library': () => I18n.text('developer-docs-shared-components.navigation.labels.objectLibrary'),
  'sensitive-data': () => I18n.text('developer-docs-shared-components.navigation.labels.sensitiveData'),
  'calls-to-action-sdk': () => I18n.text('developer-docs-shared-components.navigation.labels.callsToActionSdk'),
  campaigns: () => I18n.text('developer-docs-shared-components.navigation.labels.campaigns')
};