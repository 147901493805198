module.exports = {
  "/": {
    "success": {
      "index page loaded": [
        "INDEX_LOAD"
      ],
      "main landing page loaded": [
        "MAIN_LANDING_PAGE_LOAD"
      ]
    },
    "error": [
      "IS_GATED_LOAD"
    ]
  },
  "/doc/:slug/*": {
    "name": "authed-mdx-doc",
    "success": {
      "docs page loaded": [
        "DOCS_LOAD"
      ]
    },
    "error": [
      "IS_GATED_LOAD"
    ]
  },
  "/guides/crm/:slug": {
    "name": "authed-mdx-doc-ats",
    "success": {
      "docs page loaded": [
        "DOCS_LOAD"
      ]
    },
    "error": [
      "IS_GATED_LOAD"
    ]
  },
  "/search": {
    "name": "search-results-page",
    "success": {
      "search result page loaded": [
        "SEARCH_PAGE_LOAD"
      ]
    },
    "error": [
      "SEARCH_PAGE_ERROR"
    ]
  },
  "/reference": {
    "name": "reference-redirect",
    "success": {
      "reference redirect loaded": [
        "LANDING_PAGE_LOAD"
      ]
    },
    "error": [
      "LANDING_PAGE_ERROR"
    ]
  },
  "/reference/:secondaryNavDir": {
    "name": "reference-landing-page",
    "success": {
      "reference landing page loaded": [
        "LANDING_PAGE_LOAD"
      ]
    },
    "error": [
      "LANDING_PAGE_ERROR"
    ]
  },
  "/reference/api/:slug/*": {
    "name": "api-reference-page",
    "success": {
      "api reference page loaded": [
        "REFERENCE_PAGE_LOAD"
      ]
    },
    "error": [
      "REFERENCE_PAGE_ERROR"
    ]
  },
  "/reference/cms/:slug/*": {
    "name": "cms-reference-page",
    "success": {
      "cms reference page loaded": [
        "MDX_DOC_PAGE_LOAD"
      ]
    },
    "error": [
      "MDX_DOC_PAGE_ERROR"
    ]
  },
  "/reference/ui-components/:slug/*": {
    "name": "ui-components-reference-page",
    "success": {
      "ui components reference loaded": [
        "MDX_DOC_PAGE_LOAD"
      ]
    },
    "error": [
      "MDX_DOC_PAGE_ERROR"
    ]
  },
  "/reference/api/:slug": {
    "name": "api-reference-ats",
    "success": {
      "api reference page loaded": [
        "REFERENCE_PAGE_LOAD"
      ],
      "api overview page loaded": [
        "MDX_DOC_PAGE_LOAD"
      ]
    },
    "error": [
      "REFERENCE_PAGE_ERROR"
    ]
  },
  "/reference/cms/:slug": {
    "name": "cms-reference-ats",
    "success": {
      "cms reference page loaded": [
        "MDX_DOC_PAGE_LOAD"
      ]
    },
    "error": [
      "MDX_DOC_PAGE_ERROR"
    ]
  },
  "/reference/ui-components/:slug": {
    "name": "ui-components-reference-ats",
    "success": {
      "ui components reference page loaded": [
        "MDX_DOC_PAGE_LOAD"
      ]
    },
    "error": [
      "MDX_DOC_PAGE_ERROR"
    ]
  },
  "/guides": {
    "name": "guides-redirect",
    "success": {
      "guides redirect loaded": [
        "LANDING_PAGE_LOAD"
      ]
    },
    "error": [
      "LANDING_PAGE_ERROR"
    ]
  },
  "/guides/:secondaryNavDir/*": {
    "name": "guides-mdx-page",
    "success": {
      "guides mdx page loaded": [
        "MDX_DOC_PAGE_LOAD"
      ]
    },
    "error": [
      "MDX_DOC_PAGE_ERROR"
    ]
  },
  "/guides/:slug": {
    "name": "guides-ats",
    "success": {
      "guides page loaded": [
        "LANDING_PAGE_LOAD"
      ],
      "guides mdx page loaded": [
        "MDX_DOC_PAGE_LOAD"
      ],
      "api guides overview page loaded": [
        "LANDING_PAGE_LOAD"
      ]
    },
    "error": [
      "LANDING_PAGE_ERROR",
      "MDX_DOC_PAGE_ERROR"
    ]
  },
  "/getting-started": {
    "name": "getting-started-redirect",
    "success": {
      "get started redirect loaded": [
        "MDX_DOC_PAGE_LOAD"
      ]
    },
    "error": [
      "MDX_DOC_PAGE_ERROR"
    ]
  },
  "/getting-started/:slug/*": {
    "name": "getting-started-mdx-page",
    "success": {
      "get started page loaded": [
        "MDX_DOC_PAGE_LOAD"
      ]
    },
    "error": [
      "MDX_DOC_PAGE_ERROR"
    ]
  },
  "/getting-started/:slug": {
    "name": "getting-started-ats",
    "success": {
      "get started page loaded": [
        "MDX_DOC_PAGE_LOAD"
      ]
    },
    "error": [
      "MDX_DOC_PAGE_ERROR"
    ]
  }
};