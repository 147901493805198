import { endpointToNameAndGroup, legacyVersionInfo } from 'developer-docs-shared-components/config/versionInfo';
import { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { selectSpecInfo } from '../selectors/allSpecs';
import { useParams } from 'react-router-dom';
/**
 * Combines legacy and non-legacy version info for a given API.
 * The legacy versions are fetched from the static `legacyVersionInfo` object.
 * The non-legacy versions are fetched from the Redux store.
 * @param path The url we want to get version info for
 * @returns An array of version info objects, both legacy and non-legacy
 */
export const useGetVersionInfo = path => {
  const api = endpointToNameAndGroup[path];
  const group = api === null || api === void 0 ? void 0 : api.group;
  const name = api === null || api === void 0 ? void 0 : api.name;
  const specInfo = useSelector(selectSpecInfo(group, name));
  const legacyVersions = useMemo(() => {
    return ((legacyVersionInfo === null || legacyVersionInfo === void 0 ? void 0 : legacyVersionInfo[path]) || []).map(v => Object.assign({}, v, {
      isLegacy: true
    }));
  }, [path]);
  const {
    versionId
  } = useParams();
  const versions = useMemo(() => {
    if (!api) return legacyVersions;
    if (!specInfo) return [];
    const isLegacyDefaultSelected = legacyVersions.some(v => v.isDefaultSelected);
    const nonLegacyVersions = specInfo.versions.map(v => {
      var _api$introContent;
      return {
        name,
        group,
        openApi: v.openApi,
        versionName: `v${v.version}`,
        isDefaultSelected: v.stage === 'LATEST' && !isLegacyDefaultSelected,
        isLegacy: false,
        introContent: {
          intro: v.introduction,
          useCase: v.useCase,
          postmanCollectionId: (_api$introContent = api.introContent) === null || _api$introContent === void 0 ? void 0 : _api$introContent.postmanCollectionId,
          relatedDocumentation: v.relatedDocumentation,
          documentationBanner: v.documentationBanner,
          requirements: v.requirements
        },
        version: v.version
      };
    }).sort((a, b) => b.version - a.version);
    return nonLegacyVersions.concat(legacyVersions);
  }, [api, group, name, specInfo, legacyVersions]);
  const version = useMemo(() => versions.find(v => v.versionName === versionId) ||
  // If versioning is not enabled, lookup the default version from versions.
  // TODO: Remove this once versioning is enabled across the board.
  versions.find(v => v.isDefaultSelected) || undefined, [versionId, versions]);
  return {
    versions,
    version
  };
};