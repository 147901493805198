import enviro from 'enviro';
import I18n from 'I18n';
import { PageTypes, Paths } from '../../../components/RoutesPublic';
import memoize from 'react-utils/memoize';
import { breadcrumbs } from 'developer-docs-shared-components/config/navData/translations/breadcrumbs';
import { apiReferenceLabels } from 'developer-docs-shared-components/config/navData/translations/labels';

/**
 * 'API & Reference' > 'API Endpoints' nav config.
 * TODO: Localize
 */
export const apiEndpointsNavConfig = memoize(() => {
  const commonPath = `${Paths.REFERENCE}/api`;
  const config = {
    commonPath,
    locatorPageType: PageTypes.REFERENCE,
    sectionLabel: I18n.text('developer-docs-ui.navigation.reference.apiEndpoints'),
    items: [{
      label: apiReferenceLabels['overview'](),
      url: `${commonPath}/overview`
    }, {
      label: breadcrumbs['analytics-and-events'](),
      items: [{
        label: breadcrumbs['custom-events'](),
        items: [{
          label: apiReferenceLabels['custom-event-completions'](),
          url: `${commonPath}/analytics-and-events/custom-events/custom-event-completions`
        }, {
          label: apiReferenceLabels['custom-event-definitions'](),
          url: `${commonPath}/analytics-and-events/custom-events/custom-event-definitions`,
          badge: 'BETA'
        }]
      }, {
        label: apiReferenceLabels['email-analytics'](),
        url: `${commonPath}/analytics-and-events/email-analytics`
      }, {
        label: apiReferenceLabels['event-analytics'](),
        url: `${commonPath}/analytics-and-events/event-analytics`
      }, {
        label: apiReferenceLabels['reporting'](),
        url: `${commonPath}/analytics-and-events/reporting`
      }, {
        label: breadcrumbs['cookie-banner'](),
        items: [{
          label: apiReferenceLabels['cookie-banner-api'](),
          url: `${commonPath}/analytics-and-events/cookie-banner/cookie-banner-api`
        }, {
          label: apiReferenceLabels['google-consent-mode'](),
          url: `${commonPath}/analytics-and-events/cookie-banner/google-consent-mode`
        }]
      }, {
        label: apiReferenceLabels['tracking-code'](),
        url: `${commonPath}/analytics-and-events/tracking-code`
      }]
    }, {
      label: breadcrumbs['app-management'](),
      items: [{
        label: apiReferenceLabels['feature-flags'](),
        url: `${commonPath}/app-management/feature-flags`,
        badge: 'BETA'
      }, {
        label: apiReferenceLabels['oauth'](),
        url: `${commonPath}/app-management/oauth`
      }, {
        label: apiReferenceLabels['webhooks'](),
        url: `${commonPath}/app-management/webhooks`
      }]
    }, {
      label: breadcrumbs['automation'](),
      items: [{
        label: apiReferenceLabels['create-manage-workflows'](),
        url: `${commonPath}/automation/create-manage-workflows`
      }, {
        label: apiReferenceLabels['custom-workflow-actions'](),
        url: `${commonPath}/automation/custom-workflow-actions`
      }, {
        label: apiReferenceLabels['custom-code-actions'](),
        url: `${commonPath}/automation/custom-code-actions`
      }, {
        label: apiReferenceLabels['sequences'](),
        url: `${commonPath}/automation/sequences`,
        badge: 'BETA'
      }]
    }, {
      label: breadcrumbs['cms'](),
      items: [{
        label: breadcrumbs['blogs'](),
        items: [{
          label: apiReferenceLabels['blog-details'](),
          url: `${commonPath}/cms/blogs/blog-details`
        }, {
          label: apiReferenceLabels['blog-authors'](),
          url: `${commonPath}/cms/blogs/blog-authors`
        }, {
          label: apiReferenceLabels['blog-posts'](),
          url: `${commonPath}/cms/blogs/blog-posts`
        }, {
          label: apiReferenceLabels['blog-tags'](),
          url: `${commonPath}/cms/blogs/blog-tags`
        }, {
          label: apiReferenceLabels['blog-topics'](),
          url: `${commonPath}/cms/blogs/blog-topics`
        }, {
          label: apiReferenceLabels['blog-comments'](),
          url: `${commonPath}/cms/blogs/blog-comments`
        }]
      }, {
        label: apiReferenceLabels['content-audit'](),
        url: `${commonPath}/cms/content-audit`
      }, {
        label: apiReferenceLabels['domains'](),
        url: `${commonPath}/cms/domains`
      }, {
        label: apiReferenceLabels['hubdb'](),
        url: `${commonPath}/cms/hubdb`
      }, {
        label: apiReferenceLabels['layouts'](),
        url: `${commonPath}/cms/layouts`
      }, {
        label: apiReferenceLabels['media-bridge'](),
        url: `${commonPath}/cms/media-bridge`
      }, {
        label: apiReferenceLabels['modules'](),
        url: `${commonPath}/cms/modules`
      }, {
        label: apiReferenceLabels['pages'](),
        url: `${commonPath}/cms/pages`
      }, {
        label: apiReferenceLabels['site-search'](),
        url: `${commonPath}/cms/site-search`
      }, {
        label: apiReferenceLabels['source-code'](),
        url: `${commonPath}/cms/source-code`
      }, {
        label: apiReferenceLabels['templates'](),
        url: `${commonPath}/cms/templates`
      }, {
        label: apiReferenceLabels['url-redirects'](),
        url: `${commonPath}/cms/url-redirects`
      }]
    }, {
      label: breadcrumbs['conversations'](),
      items: [
      /* {
      label: 'Chat widget SDK',
      items: [
        {
          // TODO: added this page to avoid the discouraged "folder click navigates to page" pattern
          label: 'Conversations SDK',
          url: '/conversation/chat-widget-sdk',
        },
        {
          label: 'Open specific chatflow',
          url: '/conversations/open-to-chatflow',
        },
      ],
      }, */
      {
        label: apiReferenceLabels['chat-widget-sdk'](),
        url: `${commonPath}/conversations/chat-widget-sdk`
      }, {
        label: apiReferenceLabels['create-custom-channels'](),
        url: `${commonPath}/conversations/create-custom-channels`,
        badge: 'BETA'
      }, {
        label: apiReferenceLabels['inbox-and-messages'](),
        url: `${commonPath}/conversations/inbox-and-messages`
      }, {
        label: apiReferenceLabels['visitor-identification'](),
        url: `${commonPath}/conversations/visitor-identification`
      }
      /* {
      label: 'Working with chat bots',
      items: [
        {
          label: 'Code snippets in bots',
          url: '/conversations/code-snippets-in-bots',
        },
        {
          label: 'Webhooks in bots',
          url: '/conversations/webhooks-in-bots',
        },
      ],
      }, */]
    }, {
      label: breadcrumbs['crm'](),
      items: [{
        label: breadcrumbs['associations'](),
        items: [{
          label: apiReferenceLabels['association-details'](),
          url: `${commonPath}/crm/associations/association-details`
        }, {
          label: apiReferenceLabels['associations-schema'](),
          url: `${commonPath}/crm/associations/associations-schema`
        }]
      }, {
        label: breadcrumbs['commerce'](),
        items: [{
          label: apiReferenceLabels['carts'](),
          url: `${commonPath}/crm/commerce/carts`,
          badge: 'NEW'
        }, {
          label: apiReferenceLabels['discounts'](),
          url: `${commonPath}/crm/commerce/discounts`
        }, {
          label: apiReferenceLabels['fees'](),
          url: `${commonPath}/crm/commerce/fees`
        }, {
          label: apiReferenceLabels['invoices'](),
          url: `${commonPath}/crm/commerce/invoices`
        }, {
          label: apiReferenceLabels['orders'](),
          url: `${commonPath}/crm/commerce/orders`,
          badge: 'NEW'
        }, {
          label: apiReferenceLabels['payments'](),
          url: `${commonPath}/crm/commerce/payments`
        }, {
          label: apiReferenceLabels['quotes'](),
          url: `${commonPath}/crm/commerce/quotes`
        }, {
          label: apiReferenceLabels['subscriptions'](),
          url: `${commonPath}/crm/commerce/subscriptions`
        }, {
          label: apiReferenceLabels['taxes'](),
          url: `${commonPath}/crm/commerce/taxes`
        }]
      }, {
        label: breadcrumbs['engagements'](),
        items: [{
          label: apiReferenceLabels['engagement-details'](),
          url: `${commonPath}/crm/engagements/engagement-details`
        }, {
          label: apiReferenceLabels['calls'](),
          url: `${commonPath}/crm/engagements/calls`
        }, {
          label: apiReferenceLabels['communications'](),
          badge: 'NEW',
          url: `${commonPath}/crm/engagements/communications`
        }, {
          label: apiReferenceLabels['email'](),
          url: `${commonPath}/crm/engagements/email`
        }, {
          label: apiReferenceLabels['meetings'](),
          url: `${commonPath}/crm/engagements/meetings`
        }, {
          label: apiReferenceLabels['notes'](),
          url: `${commonPath}/crm/engagements/notes`
        }, {
          label: apiReferenceLabels['postal-mail'](),
          url: `${commonPath}/crm/engagements/postal-mail`
        }, {
          label: apiReferenceLabels['tasks'](),
          url: `${commonPath}/crm/engagements/tasks`
        }]
      }, {
        label: breadcrumbs['extensions'](),
        items: [{
          label: apiReferenceLabels['calling-sdk'](),
          url: `${commonPath}/crm/extensions/calling-sdk`
        }, {
          label: apiReferenceLabels['crm-cards'](),
          url: `${commonPath}/crm/extensions/crm-cards`
        }, {
          label: apiReferenceLabels['extension-details'](),
          url: `${commonPath}/crm/extensions/extension-details`
        }, {
          label: apiReferenceLabels['timeline'](),
          url: `${commonPath}/crm/extensions/timeline`
        }, {
          label: apiReferenceLabels['video-conferencing'](),
          url: `${commonPath}/crm/extensions/video-conferencing`
        }]
      }, {
        label: apiReferenceLabels['contacts-timeline-embed'](),
        url: `${commonPath}/crm/contacts-timeline-embed`
      }, {
        label: apiReferenceLabels['exports'](),
        url: `${commonPath}/crm/exports`
      }, {
        label: apiReferenceLabels['imports'](),
        url: `${commonPath}/crm/imports`
      }, {
        label: apiReferenceLabels['lists'](),
        url: `${commonPath}/crm/lists`,
        badge: 'BETA'
      }, {
        label: breadcrumbs['objects'](),
        items: [{
          label: apiReferenceLabels['companies'](),
          url: `${commonPath}/crm/objects/companies`
        }, {
          label: apiReferenceLabels['contacts'](),
          url: `${commonPath}/crm/objects/contacts`
        }, {
          label: apiReferenceLabels['objects'](),
          url: `${commonPath}/crm/objects/objects`
        }, {
          label: apiReferenceLabels['deals'](),
          url: `${commonPath}/crm/objects/deals`
        }, {
          label: apiReferenceLabels['deal-splits'](),
          url: `${commonPath}/crm/objects/deal-splits`,
          badge: 'BETA'
        }, {
          label: apiReferenceLabels['feedback-submissions'](),
          url: `${commonPath}/crm/objects/feedback-submissions`
        }, {
          label: apiReferenceLabels['goals'](),
          url: `${commonPath}/crm/objects/goals`,
          badge: 'NEW'
        }, {
          label: apiReferenceLabels['leads'](),
          url: `${commonPath}/crm/objects/leads`
        }, {
          label: apiReferenceLabels['line-items'](),
          url: `${commonPath}/crm/objects/line-items`
        }, {
          label: apiReferenceLabels['schemas'](),
          url: `${commonPath}/crm/objects/schemas`
        }, {
          label: apiReferenceLabels['products'](),
          url: `${commonPath}/crm/objects/products`
        }, {
          label: apiReferenceLabels['tickets'](),
          url: `${commonPath}/crm/objects/tickets`
        }, {
          label: apiReferenceLabels['object-library'](),
          url: `${commonPath}/crm/objects/object-library`
        }]
      }, {
        label: apiReferenceLabels['owners'](),
        url: `${commonPath}/crm/owners`
      }, {
        label: apiReferenceLabels['pipelines'](),
        url: `${commonPath}/crm/pipelines`
      }, {
        label: apiReferenceLabels['properties'](),
        url: `${commonPath}/crm/properties`
      }, {
        label: apiReferenceLabels['sensitive-data'](),
        url: `${commonPath}/crm/sensitive-data`
      }, {
        label: apiReferenceLabels['limits-tracking'](),
        url: `${commonPath}/crm/limits-tracking`
      }]
    }, {
      label: breadcrumbs['library'](),
      items: [{
        label: apiReferenceLabels['files'](),
        url: `${commonPath}/library/files`
      }, {
        label: apiReferenceLabels['meetings'](),
        url: `${commonPath}/library/meetings`
      }]
    }, {
      label: breadcrumbs['marketing'](),
      items: [{
        label: apiReferenceLabels['calls-to-action-sdk'](),
        url: `${commonPath}/marketing/calls-to-action-sdk`
      }, {
        label: apiReferenceLabels['campaigns'](),
        url: `${commonPath}/marketing/campaigns`
      }, {
        label: breadcrumbs['emails'](),
        items: [{
          label: apiReferenceLabels['marketing-emails'](),
          url: `${commonPath}/marketing/emails/marketing-emails`,
          badge: 'BETA'
        }, {
          label: apiReferenceLabels['single-send-api'](),
          url: `${commonPath}/marketing/emails/single-send-api`,
          badge: 'NEW'
        }, {
          label: apiReferenceLabels['transactional-emails'](),
          url: `${commonPath}/marketing/emails/transactional-emails`
        }]
      }, {
        label: apiReferenceLabels['forms'](),
        url: `${commonPath}/marketing/forms`
      }, {
        label: apiReferenceLabels['marketing-events'](),
        url: `${commonPath}/marketing/marketing-events`
      }, ...(enviro.isProd() ? [{
        label: apiReferenceLabels['subscriptions'](),
        url: `${commonPath}/marketing/subscriptions`
      }] : []), {
        label: apiReferenceLabels['subscriptions-preferences'](),
        url: `${commonPath}/marketing/subscriptions-preferences`
      }

      // {
      //   label: labels['/marketing/social-media'](),
      //   url: '/marketing/social-media',
      // },
      // { label: 'Ecommerce bridge', url: generateUrlWithVersionFromBaseUrl('marketing/ecomm-bridge') },
      // TODO: both of these point to the same 'deprecated-apis') endpoint, kind of strange
      // { label: 'Marketing calendar', url: generateUrlWithVersionFromBaseUrl('deprecated-apis') },
      // { label: 'Social posts', url: generateUrlWithVersionFromBaseUrl('deprecated-apis') },
      ]
    }, {
      label: breadcrumbs['settings'](),
      items: [{
        label: apiReferenceLabels['account-activity-api'](),
        url: `${commonPath}/settings/account-activity-api`
      }, {
        label: apiReferenceLabels['account-information-api'](),
        url: `${commonPath}/settings/account-information-api`
      }, {
        label: apiReferenceLabels['business-units-api'](),
        url: `${commonPath}/settings/business-units-api`
      }, {
        label: apiReferenceLabels['currencies'](),
        url: `${commonPath}/settings/currencies`,
        badge: 'NEW'
      }, {
        label: breadcrumbs['users'](),
        items: [{
          label: apiReferenceLabels['user-details'](),
          url: `${commonPath}/settings/users/user-details`,
          badge: 'NEW'
        }, {
          label: apiReferenceLabels['user-provisioning'](),
          url: `${commonPath}/settings/users/user-provisioning`
        }]
      }]
    }, {
      label: breadcrumbs.otherResources(),
      items: [{
        label: apiReferenceLabels['error-handling'](),
        url: `${commonPath}/other-resources/error-handling`
      }]
    }, {
      label: breadcrumbs.legal(),
      items: [{
        label: breadcrumbs.developerPolicy(),
        url: 'https://developers.hubspot.com/legal/developer-policy'
      }, {
        label: breadcrumbs.developerTerms(),
        url: 'https://legal.hubspot.com/developer-terms'
      }, {
        label: breadcrumbs.acceptableUsePolicy(),
        url: 'https://legal.hubspot.com/acceptable-use'
      }]
    }, {
      label: apiReferenceLabels['deprecated'](),
      url: `${commonPath}/deprecated/overview`
    }]
  };
  return config;
});