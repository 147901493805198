/* eslint-disable hubspot-dev/no-private-classes */
/**
 * These labels are static labels used for navigation that do not need to be translated.
 */
export const staticLabels = {
  '/modules/export-to-template-context': 'export_to_template_context',
  '/crm-action-components/crm-action-button': 'CrmActionButton',
  '/crm-action-components/crm-action-link': 'CrmActionLink',
  '/crm-action-components/crm-card-actions': 'CrmCardActions',
  '/crm-data-components/crm-association-pivot': 'CrmAssociationPivot',
  '/crm-data-components/crm-association-property-list': 'CrmAssociationPropertyList',
  '/crm-data-components/crm-association-stage-tracker': 'CrmAssociationStageTracker',
  '/crm-data-components/crm-association-table': 'CrmAssociationTable',
  '/crm-data-components/crm-data-highlight': 'CrmDataHighlight',
  '/crm-data-components/crm-property-list': 'CrmPropertyList',
  '/crm-data-components/crm-report': 'CrmReport',
  '/crm-data-components/crm-stage-tracker': 'CrmStageTracker',
  '/crm-data-components/crm-statistics': 'CrmStatistics',
  '/standard-components/accordion': 'Accordion',
  '/standard-components/alert': 'Alert',
  '/standard-components/box': 'Box',
  '/standard-components/button-row': 'ButtonRow',
  '/standard-components/button': 'Button',
  '/standard-components/charts/bar-chart': 'BarChart',
  '/standard-components/charts/line-chart': 'LineChart',
  '/standard-components/checkbox': 'Checkbox',
  '/standard-components/date-input': 'DateInput',
  '/standard-components/description-list': 'DescriptionList',
  '/standard-components/divider': 'Divider',
  '/standard-components/dropdown': 'Dropdown',
  '/standard-components/empty-state': 'EmptyState',
  '/standard-components/error-state': 'ErrorState',
  '/standard-components/flex': 'Flex',
  '/standard-components/form': 'Form',
  '/standard-components/heading': 'Heading',
  '/standard-components/icon': 'Icon',
  '/standard-components/image': 'Image',
  '/standard-components/input': 'Input',
  '/standard-components/link': 'Link',
  '/standard-components/list': 'List',
  '/standard-components/loading-button': 'LoadingButton',
  '/standard-components/loading-spinner': 'LoadingSpinner',
  '/standard-components/modal': 'Modal',
  '/standard-components/multi-select': 'MultiSelect',
  '/standard-components/number-input': 'NumberInput',
  '/standard-components/panel': 'Panel',
  '/standard-components/progress-bar': 'ProgressBar',
  '/standard-components/radio-button': 'RadioButton',
  '/standard-components/select': 'Select',
  '/standard-components/statistics': 'Statistics',
  '/standard-components/status-tag': 'StatusTag',
  '/standard-components/step-indicator': 'StepIndicator',
  '/standard-components/stepper-input': 'StepperInput',
  '/standard-components/table': 'Table',
  '/standard-components/tag': 'Tag',
  '/standard-components/text-area': 'TextArea',
  '/standard-components/text': 'Text',
  '/standard-components/tile': 'Tile',
  '/standard-components/toggle-group': 'ToggleGroup',
  '/standard-components/toggle': 'Toggle',
  '/cms/hubdb': 'HubDB',
  '/content/data-driven-content/dynamic-pages/hubdb': 'HubDB'
};