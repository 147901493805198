import { urlKeys, topLevelDirectories } from 'developer-docs-source/urlKeys';
export const GUIDES_PREFIX = 'guides';
export const REFERENCE_PREFIX = 'reference';
export const GETTING_STARTED_PREFIX = 'getting-started';
export const API_GUIDES_PREFIX = `${GUIDES_PREFIX}/api`;
export const API_REFERENCE_PREFIX = `${REFERENCE_PREFIX}/api`;
export const RATE_LIMIT_DOCUMENTAION = 'https://developers.hubspot.com/docs/api/usage-details#rate-limits';
const enUsKeys = urlKeys['en-us'];
export const getFullCtxKey = (section, doc) => `./${section}/${doc}`;
const keyToAbsolutePath = key => `.${key}`;
const isSection = section => {
  return topLevelDirectories.includes(section);
};
const isValidDocForLocale = (doc, section, locale) => {
  return urlKeys[locale || 'en-us'][section].includes(doc);
};
export const parseContextKeyForLocale = (key, locale) => {
  const cleanedKey = key.startsWith('./') ? key.slice(2) : key;

  // Dynamically create a regex pattern from topLevelDirectories
  const directoryPattern = topLevelDirectories.join('|');
  const regex = new RegExp(`^(${directoryPattern})/?(.*)$`);
  const match = cleanedKey.match(regex);
  if (match) {
    const [, section, doc] = match;
    if (isSection(section) && isValidDocForLocale(doc, section, locale)) {
      return {
        section,
        doc: doc || ''
      };
    }
  }
  return {
    section: undefined,
    doc: undefined
  };
};
export const SUPPORTED_GUIDE_DOCS = new Set(enUsKeys[API_GUIDES_PREFIX].map(key => keyToAbsolutePath(`/${API_GUIDES_PREFIX}/${key}`)));
export const SUPPORTED_CMS_GUIDE_DOCS = new Set(enUsKeys['guides/cms'].map(key => keyToAbsolutePath(`/${GUIDES_PREFIX}/cms/${key}`)));
export const SUPPORTED_GET_STARTED_DOCS = new Set(enUsKeys[GETTING_STARTED_PREFIX].map(key => keyToAbsolutePath(`/${GETTING_STARTED_PREFIX}/${key}`)));
export const SUPPORTED_CRM_CUSTOMIZATION_DOCS = new Set(enUsKeys['guides/crm'].map(key => keyToAbsolutePath(`/guides/crm/${key}`)));
export const SUPPORTED_UI_COMPONENTS_DOCS = new Set(enUsKeys['reference/ui-components'].map(key => keyToAbsolutePath(`/reference/ui-components/${key}`)));
export const SUPPORTED_APPS_DOCS = new Set(enUsKeys['guides/apps'].map(key => keyToAbsolutePath(`/guides/apps/${key}`)));
export const SUPPORTED_CMS_REFERENCE_DOC = new Set(enUsKeys['reference/cms'].map(key => keyToAbsolutePath(`/reference/cms/${key}`)));
export const SUPPORTED_API_MDX_DOCS = new Set(['./reference/api/overview', './reference/api/deprecated/overview', './reference/api/analytics-and-events/cookie-banner/cookie-banner-api', './reference/api/analytics-and-events/cookie-banner/google-consent-mode', './reference/api/analytics-and-events/tracking-code', './reference/api/analytics-and-events/tracking-code/v1', './reference/api/conversations/chat-widget-sdk', './reference/api/automation/custom-code-actions', './reference/api/deprecated/migrations/migrate-an-existing-account-extension-integration', './reference/api/deprecated/migrations/migrate-an-existing-ecommerce-bridge-api-integration', './reference/api/other-resources/error-handling', './reference/api/crm/sensitive-data', './reference/api/marketing/calls-to-action-sdk']);
export const ctxKeyToPath = ctxKey => {
  return ctxKey.replace('.', '').replace(/\$/, '');
};
export const guidesCtxKeyToPath = ctxKey => {
  return ctxKey.replace('.', '/guides').replace(/\$/, '');
};
export const docPathToCtxKey = docsPath => {
  return `${docsPath.replace(/^\/doc/, '.')}`;
};
export const isGuideDoc = ctxKey => {
  return SUPPORTED_GUIDE_DOCS.has(ctxKey);
};
export const isCMSDevelopmentDoc = ctxKey => {
  return SUPPORTED_CMS_GUIDE_DOCS.has(ctxKey);
};
export const isGettingStartedDoc = ctxKey => {
  return SUPPORTED_GET_STARTED_DOCS.has(ctxKey);
};
export const isCRMCustomizationDoc = ctxKey => {
  return SUPPORTED_CRM_CUSTOMIZATION_DOCS.has(ctxKey);
};
export const isUIComponentsDoc = ctxKey => {
  return SUPPORTED_UI_COMPONENTS_DOCS.has(ctxKey);
};
export const isAppsDoc = ctxKey => SUPPORTED_APPS_DOCS.has(ctxKey);
export const isCmsReferenceDoc = ctxKey => SUPPORTED_CMS_REFERENCE_DOC.has(ctxKey);
export const isSupportedApiMdxDoc = ctxKey => SUPPORTED_API_MDX_DOCS.has(ctxKey);
export const isMdxDoc = ctxKey => {
  const mdxKey = keyToAbsolutePath(ctxKey);
  return isGuideDoc(mdxKey) || isCMSDevelopmentDoc(mdxKey) || isGettingStartedDoc(mdxKey) || isCRMCustomizationDoc(mdxKey) || isUIComponentsDoc(mdxKey) || isAppsDoc(mdxKey) || isCmsReferenceDoc(mdxKey) || isSupportedApiMdxDoc(mdxKey);
};
export const getQueryParam = searchParamKey => {
  const searchParams = new URLSearchParams(window.location.search);
  return searchParams.get(searchParamKey);
};