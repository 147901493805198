import I18n from 'I18n';
import { navigation } from 'developer-docs-source/navigation';
import memoize from 'react-utils/memoize';
import { PageTypes, Paths } from '../../../components/RoutesPublic';
import { addLabelAndVerifyBadge } from '../navUtils';

/**
 * 'API and Reference' > 'CMS Reference' secondary nav config.
 */
export const cmsReferenceNavConfig = memoize(() => {
  const commonPath = `${Paths.REFERENCE}/cms`;
  const nav = navigation[commonPath];
  const navigationItems = nav.items.map(item => {
    return addLabelAndVerifyBadge(item, commonPath);
  });
  const config = {
    locatorPageType: PageTypes.REFERENCE,
    sectionLabel: I18n.text('developer-docs-ui.navigation.reference.cmsReference'),
    commonPath,
    items: navigationItems
  };
  return config;
});