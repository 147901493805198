'use es6';

import { Map as ImmutableMap } from 'immutable';
import I18n from 'I18n';
import { flatten, keys, pairs } from 'underscore';
import Swagger from '../../../lib/utils/SwaggerClient';
import OAS_EXTENSIONS from '../../../lib/api-explorer/oas-extensions';
import createDocs from '../../../lib/api-explorer/create-docs';
import Oas, { Operation } from '../../../lib/api-explorer/models/Oas';
import getPath from '../../../lib/api-explorer/get-path';
import oasToHar from '../../../lib/api-explorer/oas-to-har';
import parametersToJsonSchema from '../../../lib/api-explorer/parameters-to-json-schema';
import { getDefaultFormState } from '../../ParamForm/utils/defaults';
import { SwaggerToDocsAuthTypes, AuthTypes, AuthTypesWithVersions } from '../../TryIt/constants';
import Methods from '../constants/Methods';
import { getRequestBodyExample } from './ExampleUtils';
export const getSecurityTypes = security => {
  if (!security) {
    return [];
  }
  return security.map(securityType => {
    const type = Object.keys(securityType)[0].split('-')[0];
    return SwaggerToDocsAuthTypes[type];
  });
};
export const getRequestBodyPaths = (oas = {}) => {
  const {
    paths = []
  } = oas;
  const allPaths = keys(paths).map(path => {
    const methods = paths[path];
    return pairs(methods).filter(([__, meta]) => meta && meta.requestBody).map(([method]) => [path, method]);
  });
  return flatten(allPaths, 2);
};

/**
 * wherever there is a schema (in components -> schemas) where there is an allOf with two objects
 * discard the first object (that includes the $ref key) and move the contents of the second object to th parent
 */
export const fixSchema = oas => {
  if (!oas.components || !oas.components.schemas) return oas;
  const schemas = oas.components.schemas;
  Object.keys(oas.components.schemas).forEach(schemaName => {
    const schema = oas.components.schemas[schemaName];
    if (schema.allOf) {
      schemas[schemaName] = schema.allOf[1];
    }
  });
  oas.components.schemas = schemas;
  return oas;
};
export const denormalizeRequestBody = (path = [], oas = {}) => {
  const fixedSchema = fixSchema(oas);
  return Swagger.resolve({
    spec: fixedSchema,
    pathDiscriminator: ['paths', ...path, 'requestBody'],
    skipNormalization: true
  }).then(result => {
    const errors = result.errors || [];
    const spec = result.spec || {};
    // SwaggerClient is "async" in that it returns a promise, but mutation
    // happens synchronously. This just reports failures for Sentry, etc.
    if (errors && errors.length) {
      throw new Error(`[Swagger.resolve] Failed to resolve paths: ${path.join('->')}`);
    }
    return {
      errors,
      spec
    };
  });
};
export function getOperation(doc, oas) {
  let operation = doc.swagger ? oas.operation(doc.swagger.path, doc.api.method) : null;
  if (!getPath(oas, doc)) {
    operation = new Operation(oas, doc.swagger.path, doc.api.method, {
      parameters: doc.api.params
    });
  }
  return operation;
}
export const getSpecificationWithExtensions = data => Object.assign({}, OAS_EXTENSIONS, data, {
  'x-proxy-enabled': false
});
export const getOasDocs = specificationWithExtensions => {
  if (!specificationWithExtensions) {
    return null;
  }
  return createDocs(specificationWithExtensions, 'api-setting');
};
export const getOas = specificationWithExtensions => {
  if (!specificationWithExtensions) {
    return null;
  }
  return new Oas(specificationWithExtensions, {
    apiKey: ''
  });
};
export const compareOasDocs = orderedSlugs => {
  return (a, b) => {
    orderedSlugs = orderedSlugs || [];

    // Sort by slug
    if (!a || !a.slug || !b || !b.slug) {
      return 0;
    }

    // Both are in the provided list of ordered slugs
    if (orderedSlugs.indexOf(a.slug) > -1 && orderedSlugs.indexOf(b.slug) > -1) {
      if (orderedSlugs.indexOf(a.slug) < orderedSlugs.indexOf(b.slug)) {
        return -1;
      }
      if (orderedSlugs.indexOf(a.slug) > orderedSlugs.indexOf(b.slug)) {
        return 1;
      }
    }

    // One but not the other is the provided list of ordered slugs
    if (orderedSlugs.indexOf(a.slug) > -1 && orderedSlugs.indexOf(b.slug) === -1) {
      return -1;
    }
    if (orderedSlugs.indexOf(a.slug) === -1 && orderedSlugs.indexOf(b.slug) > -1) {
      return 1;
    }

    // Neither are in the provided list of ordered slugs
    if (a.slug < b.slug) {
      return -1;
    }
    if (a.slug > b.slug) {
      return 1;
    }

    // The slugs are the same, sort by path
    if (!a.swagger || !a.swagger.path || !b.swagger || !b.swagger.path) {
      return 0;
    }
    if (a.swagger.path < b.swagger.path) {
      return -1;
    }
    if (a.swagger.path > b.swagger.path) {
      return 1;
    }

    // The paths are the same, use HTTP method
    if (!a.api || !a.api.method || !b.api || !b.api.method) {
      return 0;
    }
    if (Methods.indexOf(a.api.method.toUpperCase()) < Methods.indexOf(b.api.method.toUpperCase())) {
      return -1;
    }
    if (Methods.indexOf(a.api.method.toUpperCase()) > Methods.indexOf(b.api.method.toUpperCase())) {
      return 1;
    }

    // TODO: Some order enforcement / tie breaker for batch?
    return 0;
  };
};
export const getFilteredAndSortedOasDocs = (docs, orderedSlugs) => {
  return docs.filter(doc => {
    if (typeof doc.api !== 'undefined' && doc.api.method === 'parameters') {
      return false;
    }
    return true;
  }).sort(compareOasDocs(orderedSlugs));
};
const getPlaceholderAuth = security => {
  const securityTypes = getSecurityTypes(security);
  if (securityTypes.includes(AuthTypes.OAUTH_TOKEN)) {
    return {
      oauth2: I18n.text('developer-docs-shared-components.tryIt.placeholderKeys.OAUTH_TOKEN'),
      authType: AuthTypes.OAUTH_TOKEN
    };
  }
  if (securityTypes.includes(AuthTypesWithVersions.LEGACY_OAUTH_TOKEN)) {
    return {
      oauth2_legacy: I18n.text('developer-docs-shared-components.tryIt.placeholderKeys.OAUTH_TOKEN'),
      authType: AuthTypesWithVersions.LEGACY_OAUTH_TOKEN
    };
  }
  if (securityTypes.includes(AuthTypes.DEVELOPER_API_KEY)) {
    return {
      developer_hapikey: I18n.text('developer-docs-shared-components.tryIt.placeholderKeys.DEVELOPER_API_KEY'),
      authType: AuthTypes.DEVELOPER_API_KEY
    };
  }
  return {};
};
const getOasForDoc = (doc, oasFiles) => {
  const apiSetting = doc.category.apiSetting || typeof doc.api.apiSetting === 'string' && doc.api.apiSetting || typeof doc.api.apiSetting === 'object' && doc.api.apiSetting && doc.api.apiSetting._id;
  return new Oas(oasFiles[apiSetting]);
};
export const createHarStateFromDocs = (filteredOasDocs, specWithExtensions) => {
  const harStateObj = filteredOasDocs.reduce((acc, doc) => {
    const oas = getOasForDoc(doc, {
      'api-setting': specWithExtensions
    });
    const operation = getOperation(doc, oas);
    const auth = getPlaceholderAuth(operation.security);
    const jsonSchema = parametersToJsonSchema(operation, oas) || [];
    const formData = jsonSchema.reduce((_acc, schema) => {
      _acc[schema.type] = getDefaultFormState(schema.schema, {}, schema.schema.definitions);
      return _acc;
    }, {});

    // On the initial doc conversion, start with the full example data
    const example = getRequestBodyExample(operation, oas, false);
    if (example && example.data) {
      formData.body = example.data;
    }

    // If there are path parameters, set them to a default value
    if (formData.path && Object.keys(formData.path).length > 0) {
      const pathParams = operation.parameters.filter(param => {
        return param.in === 'path';
      });
      for (const pathParam of pathParams) {
        const pathParamName = pathParam.name;
        if (pathParam.schema.type === 'string') {
          formData.path[pathParamName] = pathParamName;
        } else {
          formData.path[pathParamName] = 0;
        }
      }
    }
    acc[doc._id] = new ImmutableMap({
      oas,
      operation,
      formData,
      auth,
      har: oasToHar({
        oas,
        pathOperation: operation,
        values: formData,
        auth
      })
    });
    return acc;
  }, {});
  return new ImmutableMap(harStateObj);
};
export const updateHarStateWithAuthChange = (auth, oldHarState) => {
  return oldHarState.map(val => {
    const {
      oas,
      operation,
      formData
    } = val.toJS();
    return new ImmutableMap({
      oas,
      operation,
      formData,
      auth,
      har: oasToHar({
        oas,
        pathOperation: operation,
        values: formData,
        auth
      })
    });
  });
};
export const updateHarStateWithFormData = (formData, docId, oldHarState) => {
  const {
    formData: oldFormData,
    oas,
    operation,
    auth
  } = oldHarState.get(docId).toJS();
  const mergedFormData = Object.assign({}, oldFormData, formData);
  return oldHarState.setIn([docId, 'formData'], mergedFormData).setIn([docId, 'har'], oasToHar({
    oas,
    pathOperation: operation,
    values: mergedFormData,
    auth
  }));
};