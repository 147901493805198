import { useCallback, useEffect, useState } from 'react';
import { importMdx } from 'developer-docs-source';
import { parseContextKeyForLocale } from 'developer-docs-mdx-components/utils/urls';
import { useGetVersionInfo } from './useVersionInfo';
const useMDXContent = ({
  ctxKey,
  locale
}) => {
  const [loading, setLoading] = useState(true);
  const [mdx, setMdx] = useState(null);
  const [mdxError, setMdxError] = useState(null);
  const {
    version
  } = useGetVersionInfo(ctxKey);
  const loadDocKeys = useCallback(async () => {
    const {
      section,
      doc
    } = parseContextKeyForLocale(ctxKey, locale);

    // if the locale doesn't have the section, bail out
    if (!(version !== null && version !== void 0 && version.isLegacy) && (!section || !doc)) {
      setLoading(false);
      return;
    }
    try {
      setLoading(true);
      let cleanedCtxKey = '';
      if (version !== null && version !== void 0 && version.isLegacy) {
        // Legacy docs content, if there is a legacy url provided for the version, use that over the apiKey
        cleanedCtxKey = `api${version.legacyUrl ? version.legacyUrl : `${ctxKey}${version ? `/${version.versionName}` : ''}`}`;
      } else {
        cleanedCtxKey = (ctxKey || '').replace(/^\.\//, '').trim();
      }
      const localeModule = await importMdx(version !== null && version !== void 0 && version.isLegacy ? 'en-us/docs-legacy' : locale, cleanedCtxKey);
      setMdx(localeModule);
    } catch (error) {
      setMdxError(error);
    } finally {
      setLoading(false);
    }
  }, [ctxKey, locale, version]);
  useEffect(() => {
    void loadDocKeys();
  }, [loadDocKeys]);
  return {
    loading,
    mdx,
    mdxError,
    loadDocKeys
  };
};
export default useMDXContent;