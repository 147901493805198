import { apiReferenceLabels } from './translations/labels';
import { breadcrumbs } from './translations/breadcrumbs';
import { assertIsBreadcrumbKeyArray, isApiReferenceLabel } from './types';

/**
 * extracts the key portion from the URL e.g. /reference/api/contacts/v3#tag -> /reference/api/contacts
 * @param url - The URL to extract the key from.
 * @returns A key that corresponds to a key in `keyToNavData`.
 */
export function getKeyFromUrl(url) {
  // the url followed by an optional '/v[number]' where number is one or more digits, and an optional '#...' part.
  // The '?' after the version and tag sections makes them non-capturing groups.
  const regex = /(.*?)(?:\/v\d+|#|$)/;
  const match = url.match(regex);
  // If there was a match, return the first capturing group (the part of the URL we're interested in).
  // If there was no match, return the original URL.
  // The replace function is used to remove the version and tag sections from the match.
  return match ? match[1].replace(/(\/v\d+|#.*)$/, '') : url;
}
export const derivePathFromUrlParts = urlParts => {
  return () => urlParts.map(p => breadcrumbs[p]());
};
export const deriveApiReferenceLabelFromUrl = label => {
  return () => apiReferenceLabels[label]();
};
export const derivePathFromUrl = url => {
  // split the url into parts where there is a /
  const urlParts = url.split('/').filter(part => part !== '');

  // the path is all the parts except the last one
  const urlBreadcrumbs = urlParts.slice(0, urlParts.length - 1);
  assertIsBreadcrumbKeyArray(urlBreadcrumbs);
  return {
    path: derivePathFromUrlParts(urlBreadcrumbs)
  };
};
export const deriveApiReferenceLabelAndPathFromUrl = url => {
  const urlParts = url.split('/').filter(part => part !== '');
  const label = urlParts[urlParts.length - 1];
  if (!isApiReferenceLabel(label)) {
    return null;
  }

  // the path is all the parts except the last one
  const urlBreadcrumbs = urlParts.slice(0, urlParts.length - 1);
  assertIsBreadcrumbKeyArray(urlBreadcrumbs);
  return {
    label: deriveApiReferenceLabelFromUrl(label),
    path: derivePathFromUrlParts(urlBreadcrumbs)
  };
};